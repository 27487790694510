import { useQuery } from '@tanstack/react-query';
import { ZMerchantReservation } from '../types/merchant-reservation';
import useV2Api from './use-v2-api';
import useSession from './use-session';
import { OutletEntitlement } from '../types/outlet.base';

interface FeatureFlags {
  confirmationNotificationEnabled: boolean;
  isMagicBellEnabled: boolean;
  isEnableTableCombination: boolean;
  isEnablePaymentRequest: boolean;
  entitlements: OutletEntitlement[];
  refetch: () => void;
}

export const useFeatureFlags = (): FeatureFlags => {
  const v2Api = useV2Api();
  const { data: sessionData } = useSession();

  const { data, refetch } = useQuery(
    ['get-merchant-reservation-query', 'feature-flags'],
    async () =>
      v2Api
        .get(`/masterdata/merchant-reservation`)
        .then((res) => ZMerchantReservation.parse(res.data)),
    { enabled: Boolean(sessionData?.outlet?.id) }
  );

  if (!data) {
    return {
      confirmationNotificationEnabled: false,
      isMagicBellEnabled: false,
      isEnableTableCombination: false,
      isEnablePaymentRequest: false,
      entitlements: [],
      refetch: () => {},
    };
  }
  const { brand, outlet } = data;

  return {
    confirmationNotificationEnabled: !!outlet.confirmationNotificationEnabled,
    isMagicBellEnabled: !!brand?.isEnableMagicBell,
    isEnableTableCombination: !!brand?.isEnableTableCombination,
    isEnablePaymentRequest: !!brand?.isEnablePaymentRequest,
    entitlements: outlet.entitlements || [],
    refetch,
  };
};
