import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import useV2Api from './use-v2-api';
import { z } from 'zod';
import {
  AddPartySizePayload,
  PartySizesRes,
  ZUpdateListCombinationsPayload,
  ZCreateCombinationPayload,
  ZPartySizesRes,
  ZPartySizeWithoutRef,
  UpdateListCombinationsPayload,
} from '@/common/types/table-combinations';
import React from 'react';
import { useDebounceFn } from './use-debounce';
import {
  AffectedReason,
  AffectedReservation,
  ZAffectedReservation,
  affectedResByReason,
} from '@/common/types/reservation';

export const useAddPartySize = () => {
  const v2Api = useV2Api();

  const addPartySize = React.useMemo(
    () =>
      z
        .function()
        .args(AddPartySizePayload)
        .implement(async (payload: AddPartySizePayload) => {
          const { data } = await v2Api.post(
            'combinations/group-pax-combinations',
            payload
          );
          return ZPartySizeWithoutRef.parse(data);
        }),
    [v2Api]
  );

  const mutation = useMutation({
    mutationFn: addPartySize,
  });

  return {
    ...mutation,
  };
};

export const useGetGroupPaxCombinations = ({
  params,
  config,
  debounceMs = 300,
}: {
  params?: any;
  config?: UseQueryOptions<PartySizesRes, Error, PartySizesRes, Array<any>>;
  debounceMs?: number;
}) => {
  const v2Api = useV2Api();
  const queryFn = useDebounceFn(async () => {
    const { data } = await v2Api.get('combinations/group-pax-combinations', {
      params,
    });

    return ZPartySizesRes.parse(data);
  }, debounceMs);

  const query = useQuery(['table-combinations', params], queryFn, config);

  return query;
};

export const useAddCombinations = () => {
  const v2Api = useV2Api();
  const mutation = useMutation(
    z
      .function()
      .args(ZCreateCombinationPayload)
      .implement(async (payload) => {
        const { data } = await v2Api.post('/combinations', payload);
        return data;
      })
  );
  return mutation;
};

export const useUpdateListCombinations = () => {
  const v2Api = useV2Api();

  const updateListCombinations = React.useMemo(
    () =>
      z
        .function()
        .args(ZUpdateListCombinationsPayload)
        .implement(async (payload: UpdateListCombinationsPayload) => {
          const id = z.string().parse(payload?.id);
          await v2Api.put(`combinations/${id}/group-pax-combinations`, {
            combinations: payload?.combinations,
          });
        }),
    [v2Api]
  );

  const mutation = useMutation({
    mutationFn: updateListCombinations,
  });

  return {
    ...mutation,
  };
};

export const ZUpdateTablesResponse = z.object({
  affectedReservations: z.array(ZAffectedReservation).nullish(),
  success: z.boolean(),
});

export const useUpdateListTableCombinations = () => {
  const v2Api = useV2Api();

  const checkListResrvTableCombinations = React.useMemo(
    () =>
      z
        .function()
        .args(ZUpdateListCombinationsPayload)
        .implement(async (payload: UpdateListCombinationsPayload) => {
          const id = z.string().parse(payload?.id);
          const { data } = await v2Api.put(
            `combinations/${id}/group-pax-combinations`,
            {
              combinations: payload?.combinations,
            }
          );
          const parsedData = ZUpdateTablesResponse.parse(data);
          const affectedTablesCombination = parsedData.affectedReservations;
          let warningAffectedRes: AffectedReservation[] = [];
          if (affectedTablesCombination?.length) {
            warningAffectedRes = affectedResByReason(
              affectedTablesCombination,
              [AffectedReason.REMOVE_TABLE, AffectedReason.TABLE_DISABLE]
            );
          }

          return { ...parsedData, warningAffectedRes };
        }),
    [v2Api]
  );

  const mutation = useMutation({
    mutationFn: checkListResrvTableCombinations,
  });

  return {
    ...mutation,
  };
};
