import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import useI18nTimeUtils from '@/common/i18n-time-utils';

export type OptionType = {
  value: string;
  label: React.ReactElement | string;
};

export type Option = Record<string, OptionType>;

export const useGenerateAllowSpecificTimeRefundOptions = (): Record<
  | '1_day'
  | '2_days'
  | '3_days'
  | '4_days'
  | '5_days'
  | '6_days'
  | '1_week'
  | '2_weeks'
  | '3_weeks'
  | '4_weeks',
  OptionType
> => {
  const { formatDuration } = useI18nTimeUtils();

  return useMemo(
    () => ({
      '1_day': { value: '1440', label: formatDuration({ days: 1 }) },
      '2_days': { value: '2880', label: formatDuration({ days: 2 }) },
      '3_days': { value: '4320', label: formatDuration({ days: 3 }) },
      '4_days': { value: '5760', label: formatDuration({ days: 4 }) },
      '5_days': { value: '7200', label: formatDuration({ days: 5 }) },
      '6_days': { value: '8640', label: formatDuration({ days: 6 }) },
      '1_week': { value: '10080', label: formatDuration({ weeks: 1 }) },
      '2_weeks': { value: '20160', label: formatDuration({ weeks: 2 }) },
      '3_weeks': { value: '30240', label: formatDuration({ weeks: 3 }) },
      '4_weeks': { value: '40320', label: formatDuration({ weeks: 4 }) },
    }),
    [formatDuration]
  );
};

export const useGenerateRefundOptions = (): Record<
  | 'non_refundable'
  | '15_mins'
  | '30_mins'
  | '45_mins'
  | '1_hour'
  | '2_hours'
  | '3_hours'
  | '4_hours'
  | '5_hours'
  | '6_hours'
  | '12_hours',
  OptionType
> => {
  const { compactDuration, formatDuration } = useI18nTimeUtils();
  const { t } = useTranslation();
  const allowSpecificTimeRefundOptions =
    useGenerateAllowSpecificTimeRefundOptions();
  return useMemo(
    () => ({
      non_refundable: { value: '0', label: <Trans>Non-refundable</Trans> },
      '15_mins': {
        value: '15',
        label: compactDuration(formatDuration({ minutes: 15 }), {
          minutes: ({ digit }) => [
            t('{{digit}}min', { digit }),
            t('{{digit}}mins', { digit }),
          ],
        }),
      },
      '30_mins': {
        value: '30',
        label: compactDuration(formatDuration({ minutes: 30 }), {
          minutes: ({ digit }) => [
            t('{{digit}}min', { digit }),
            t('{{digit}}mins', { digit }),
          ],
        }),
      },
      '45_mins': {
        value: '45',
        label: compactDuration(formatDuration({ minutes: 45 }), {
          minutes: ({ digit }) => [
            t('{{digit}}min', { digit }),
            t('{{digit}}mins', { digit }),
          ],
        }),
      },
      '1_hour': { value: '60', label: formatDuration({ hours: 1 }) },
      '2_hours': { value: '120', label: formatDuration({ hours: 2 }) },
      '3_hours': { value: '180', label: formatDuration({ hours: 3 }) },
      '4_hours': { value: '240', label: formatDuration({ hours: 4 }) },
      '5_hours': { value: '300', label: formatDuration({ hours: 5 }) },
      '6_hours': { value: '360', label: formatDuration({ hours: 6 }) },
      '12_hours': { value: '720', label: formatDuration({ hours: 12 }) },
      ...allowSpecificTimeRefundOptions,
    }),
    [allowSpecificTimeRefundOptions, compactDuration, formatDuration, t]
  );
};

export const SPECIFIC_TIME_OPTIONS: Option = {
  '12:00_AM': { value: '12:00 AM', label: <>12:00 AM</> },
  '1:00_AM': { value: '01:00 AM', label: <>1:00 AM</> },
  '2:00_AM': { value: '02:00 AM', label: <>2:00 AM</> },
  '3:00_AM': { value: '03:00 AM', label: <>3:00 AM</> },
  '4:00_AM': { value: '04:00 AM', label: <>4:00 AM</> },
  '5:00_AM': { value: '05:00 AM', label: <>5:00 AM</> },
  '6:00_AM': { value: '06:00 AM', label: <>6:00 AM</> },
  '7:00_AM': { value: '07:00 AM', label: <>7:00 AM</> },
  '8:00_AM': { value: '08:00 AM', label: <>8:00 AM</> },
  '9:00_AM': { value: '09:00 AM', label: <>9:00 AM</> },
  '10:00_AM': { value: '10:00 AM', label: <>10:00 AM</> },
  '11:00_AM': { value: '11:00 AM', label: <>11:00 AM</> },
  '12:00_PM': { value: '12:00 PM', label: <>12:00 PM</> },
  '1:00_PM': { value: '01:00 PM', label: <>1:00 PM</> },
  '2:00_PM': { value: '02:00 PM', label: <>2:00 PM</> },
  '3:00_PM': { value: '03:00 PM', label: <>3:00 PM</> },
  '4:00_PM': { value: '04:00 PM', label: <>4:00 PM</> },
  '5:00_PM': { value: '05:00 PM', label: <>5:00 PM</> },
  '6:00_PM': { value: '06:00 PM', label: <>6:00 PM</> },
  '7:00_PM': { value: '07:00 PM', label: <>7:00 PM</> },
  '8:00_PM': { value: '08:00 PM', label: <>8:00 PM</> },
  '9:00_PM': { value: '09:00 PM', label: <>9:00 PM</> },
  '10:00_PM': { value: '10:00 PM', label: <>10:00 PM</> },
  '11:00_PM': { value: '11:00 PM', label: <>11:00 PM</> },
};

export interface CreateEditTicketObject {
  brandId: string;
  name: string;
  description: string;
  image: string;
  color: string;
  isFeatureTicket: boolean;
  enableGoogleBooking: boolean;
  paymentRequired: boolean;
  paymentType: 'deposit' | 'prepayment' | 'card-guarantee';
  depositAmount: string | undefined;
  depositRefundWindow: string | undefined;
  depositType: 'PER_RESERVATION' | 'PER_CUSTOMER';
  isDepositBeforeASpecificTime: boolean;
  depositSpecificTime: string | undefined;
  prepaymentAmount: string | undefined;
  prepaymentRefundWindow: string | undefined;
  prepaymentType: 'PER_RESERVATION' | 'PER_CUSTOMER';
  isPrepaymentBeforeASpecificTime: boolean;
  prepaymentSpecificTime: string | undefined;
  usualPrice: string | undefined;
  isShowUsualPrice: boolean;
  cardGuaranteeAmount: string | undefined;
  cardGuaranteeType: 'PER_RESERVATION' | 'PER_CUSTOMER';
  cardGuaranteeRefundWindow: string | undefined;
  isCardGuaranteeBeforeASpecificTime: boolean;
  cardGuaranteeSpecificTime: string | undefined;
  isEnableSelfEdit?: boolean | null;
  editWindow?: number | null;
  typeEditWindow?: TypeSelfEditWindow | null;
  showContactEditPage?: boolean | null;
  showContactCancelPage?: boolean | null;
}

export enum TypeSelfEditWindow {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  ANYTIME = 'anytime',
}
