import React, { ForwardedRef, ReactNode, useId } from 'react';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useResponsive from '@/minimals.cc/hooks/useResponsive';

type TValue = string | number;
type MuiEmpty = '' | null;
export interface SelectOption {
  value: TValue;
  label: ReactNode;
  disabled?: boolean;
}

export interface SelectProps<T extends TValue = TValue>
  extends Omit<
    MuiSelectProps<T>,
    'error' | 'onChange' | 'multiple' | 'value' | 'defaultValue' | 'renderValue'
  > {
  onChange?: (newVal: T) => void;
  options: Readonly<Array<SelectOption>>;
  error?: string;
  fullWidth?: boolean;
  value?: T | MuiEmpty;
  defaultValue?: T | MuiEmpty;
  /**
   * disable multiple select to avoid compat issues with native select
   */
  multiple?: never;
  /**
   * disable renderValue to avoid compat issues with native select
   */
  renderValue?: never;
}

type Wrapped = { v: TValue };
const encode = (v: MuiEmpty | TValue | undefined) => {
  if (v === undefined) return undefined;
  if (v === null || v === '') return '' as const;
  const payload: Wrapped = { v };
  return JSON.stringify(payload);
};
const decode = (str: string) => str && (JSON.parse(str) as Wrapped).v;

const Select = React.forwardRef(function Select<T extends TValue>(
  props: SelectProps<T>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const selectLabelId = useId();
  const {
    options,
    error,
    fullWidth = true,
    onChange,
    value: _value,
    defaultValue: _defaultValue,
    native: _native,
    ..._restProps
  } = props;

  /**
   * encode `value` and `defaultValue` using JSON.stringify to preserve type when using native select option
   */
  const value = encode(_value);
  const defaultValue = encode(_defaultValue);
  /**
   * because we're encoding `value` and `defaultValue` from `TValue` to `string`
   * -> need to update `restProps` to `SelectProps<string>` as well
   */
  const restProps = _restProps as Pick<
    SelectProps<string>,
    keyof typeof _restProps
  >;

  const isDesktop = useResponsive('up', 'lg');
  const native = _native ?? !isDesktop;

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id={selectLabelId}>{restProps.label}</InputLabel>
      <MuiSelect
        labelId={selectLabelId}
        ref={ref}
        error={!!error}
        IconComponent={(props) => (
          <KeyboardArrowDownIcon
            {...props}
            sx={{
              width: '24px',
              height: '24px',
              marginRight: '5px',
              top: 'auto !important',
            }}
          />
        )}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '300px',
              marginBottom: '16px',
            },
          },
        }}
        native={native}
        onChange={
          onChange && ((event) => onChange(decode(event.target.value) as T))
        }
        value={value}
        defaultValue={defaultValue}
        {...restProps}
      >
        {options.map((option, i) =>
          !native ? (
            <MenuItem
              key={i}
              value={encode(option.value)}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          ) : (
            <option
              key={i}
              value={encode(option.value)}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          )
        )}
      </MuiSelect>

      {!!error && (
        <FormHelperText sx={{ marginLeft: 0, marginRight: 0 }} error={!!error}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
});

export default Select;
