export { css } from '@emotion/react';

export default function BlockDisturb() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          opacity="0.3"
          d="M9.99268 3.05957V3.3329C9.99268 4.06624 9.39268 4.66624 8.65935 4.66624H7.32602V5.99957C7.32602 6.36624 7.02602 6.66624 6.65935 6.66624H5.32602V7.99957H9.32602C9.69268 7.99957 9.99268 8.29957 9.99268 8.66624V10.6662H10.6593C11.2527 10.6662 11.7527 11.0596 11.926 11.5996C12.7927 10.6529 13.3327 9.38624 13.3327 7.99957C13.3327 5.76624 11.946 3.84624 9.99268 3.05957ZM5.99268 10.6662V9.99957L2.80602 6.8129C2.71935 7.1929 2.66602 7.5929 2.66602 7.99957C2.66602 10.7129 4.70602 12.9529 7.32602 13.2862V11.9996C6.59268 11.9996 5.99268 11.3996 5.99268 10.6662Z"
          fill="#919EAB"
          fillOpacity="0.8"
        />
        <path
          d="M8.00065 1.33301C4.32065 1.33301 1.33398 4.31967 1.33398 7.99967C1.33398 11.6797 4.32065 14.6663 8.00065 14.6663C11.6807 14.6663 14.6673 11.6797 14.6673 7.99967C14.6673 4.31967 11.6807 1.33301 8.00065 1.33301ZM7.32732 13.2863C4.70732 12.953 2.66732 10.713 2.66732 7.99967C2.66732 7.59301 2.72065 7.19301 2.80732 6.81301L5.99398 9.99967V10.6663C5.99398 11.3997 6.59398 11.9997 7.32732 11.9997V13.2863ZM11.9273 11.5997C11.754 11.0597 11.2607 10.6663 10.6607 10.6663H9.99398V8.66634C9.99398 8.29967 9.69398 7.99967 9.32732 7.99967H5.32732V6.66634H6.66065C7.02732 6.66634 7.32732 6.36634 7.32732 5.99967V4.66634H8.66065C9.39398 4.66634 9.99398 4.06634 9.99398 3.33301V3.05967C11.9473 3.84634 13.334 5.76634 13.334 7.99967C13.334 9.38634 12.794 10.653 11.9273 11.5997Z"
          fill="#919EAB"
          fillOpacity="0.8"
        />
      </g>
      <path
        d="M8.00065 1.33301C4.33398 1.33301 1.33398 4.33301 1.33398 7.99967C1.33398 11.6663 4.33398 14.6663 8.00065 14.6663C11.6673 14.6663 14.6673 11.6663 14.6673 7.99967C14.6673 4.33301 11.6673 1.33301 8.00065 1.33301ZM2.66732 7.99967C2.66732 5.06634 5.06732 2.66634 8.00065 2.66634C9.20065 2.66634 10.334 3.06634 11.2673 3.79967L3.80065 11.2663C3.06732 10.333 2.66732 9.19967 2.66732 7.99967ZM8.00065 13.333C6.80065 13.333 5.66732 12.933 4.73398 12.1997L12.2007 4.73301C12.934 5.66634 13.334 6.79967 13.334 7.99967C13.334 10.933 10.934 13.333 8.00065 13.333Z"
        fill="#919EAB"
        fillOpacity="0.8"
      />
    </svg>
  );
}
