import { z } from 'zod';
import { ZAreaWithoutRef } from './area.base';
import {
  ZBlockOutDateWithoutRef,
  ZServiceTimingWithoutRef,
} from './schedule.base';
import { ZTicketWithoutRef } from './ticket.base';

export const ZServiceTiming = ZServiceTimingWithoutRef.extend({
  ticket: ZTicketWithoutRef.optional().nullable(),
  areas: z.array(ZAreaWithoutRef).optional().nullable(),
});
export type ServiceTiming = z.infer<typeof ZServiceTiming>;

export const ZBlockOutDate = ZBlockOutDateWithoutRef.extend({
  serviceTimings: z.array(ZServiceTiming),
  unblockedServiceTimings: z.array(ZServiceTiming).nullable().optional(),
});
export type BlockOutDate = z.infer<typeof ZBlockOutDate>;

export const ZBlockOutDatePayload = z.array(ZBlockOutDate);

export type BlockOutDatePayload = z.infer<typeof ZBlockOutDatePayload>;
