import React from 'react';

const LogoGoogleSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4793_49706)">
        <circle cx="8" cy="8" r="8" fill="#F4F6F8" />
        <circle cx="8" cy="8" r="7.5" stroke="#919EAB" strokeOpacity="0.32" />
        <path
          d="M5.09086 8.10639C5.09086 7.78208 5.14473 7.47116 5.24086 7.17952L3.55814 5.89453C3.23018 6.5604 3.04541 7.3107 3.04541 8.10639C3.04541 8.9014 3.22996 9.65124 3.55746 10.3167L5.23927 9.02917C5.14405 8.7389 5.09086 8.42911 5.09086 8.10639"
          fill="#FBBC05"
        />
        <path
          d="M8.04538 5.15583C8.74992 5.15583 9.38629 5.40548 9.88629 5.81399L11.3408 4.36151C10.4545 3.58988 9.31811 3.11328 8.04538 3.11328C6.06947 3.11328 4.37129 4.24327 3.55811 5.89433L5.24083 7.17932C5.62856 6.00236 6.73379 5.15583 8.04538 5.15583"
          fill="#EB4335"
        />
        <path
          d="M8.04538 11.0567C6.73379 11.0567 5.62856 10.2102 5.24083 9.0332L3.55811 10.318C4.37129 11.9693 6.06947 13.0992 8.04538 13.0992C9.26492 13.0992 10.4292 12.6662 11.3031 11.8549L9.70583 10.62C9.25515 10.904 8.68765 11.0567 8.04538 11.0567"
          fill="#34A853"
        />
        <path
          d="M12.8181 8.10604C12.8181 7.81101 12.7727 7.49328 12.7045 7.19824H8.04541V9.12732H10.7272C10.5931 9.78502 10.2281 10.2907 9.70586 10.6197L11.3031 11.8546C12.2211 11.0026 12.8181 9.7335 12.8181 8.10604"
          fill="#4285F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_4793_49706">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoGoogleSvg;
