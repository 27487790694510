import { parse } from 'date-fns';

export const getBlockStartEndTime = (b: {
  date: string;
  from: string;
  to: string;
}) => {
  return {
    start: parse(`${b.date} ${b.from}`, 'yyyy-MM-dd HH:mm:ss', new Date()),
    end: parse(`${b.date} ${b.to}`, 'yyyy-MM-dd HH:mm:ss', new Date()),
  };
};

export const hasBlockOverlap = (
  blocks: {
    date: string;
    from: string;
    to: string;
  }[],
  selectedTime: Date
) => {
  return blocks.some((b) => {
    const { start, end } = getBlockStartEndTime(b);
    return start <= selectedTime && selectedTime <= end;
  });
};
