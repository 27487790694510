import useV2Api from '@/common/hooks/use-v2-api';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { z } from 'zod';
import {
  ZArea,
  Area,
  ZAreaTable,
  AreaTable,
  AddAreaPayload,
  GetAreasResponse,
  UpdateAreasPayload,
  AddAreaTablePayload,
  ZGetAreasResponse,
  ZUpdateAreaLayOutPayload,
  UpdateAreaLayOutPayload,
} from '@/common/types/area';
import {
  AffectedReason,
  affectedResByReason,
  AffectedReservation,
  BlockingAffectedResError,
  ZAffectedReservation,
} from '../types/reservation';
import { isAxiosError } from '../lib/axios-clean-trace';

const AREA_ENDPOINT = '/areas';
const TABLE_ENDPOINT = '/tables';

export const useAreas = (
  config?: UseQueryOptions<Area[], Error, Area[], Array<any>>
) => {
  const v2Api = useV2Api();
  const query = useQuery(
    ['table-and-floor-plan', 'area'],
    z
      .function()
      .args()
      .implement(async () => {
        const { data } = await v2Api.get(AREA_ENDPOINT);
        return GetAreasResponse.parse(data);
      }),
    config
  );
  return query;
};

export const useAddArea = () => {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(AddAreaPayload)
      .implement(async (addAreaPayload: AddAreaPayload) => {
        const { data } = await v2Api.post(AREA_ENDPOINT, addAreaPayload);
        return ZArea.parse(data);
      })
  );
};

export const ZUpdateAreasResponse = z.object({
  affectedReservations: z.array(ZAffectedReservation).nullish(),
  listReservationAffect: z.array(ZAffectedReservation).nullish(),
  success: z.boolean(),
});
export const useBulkUpdateAreas = () => {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(UpdateAreasPayload)
      .implement(async (areas: UpdateAreasPayload) => {
        const { data } = await v2Api.patch(
          AREA_ENDPOINT + '/bulk-update',
          areas
        );
        const parsedData = ZUpdateAreasResponse.parse(data);
        const affected =
          parsedData.affectedReservations || parsedData.listReservationAffect;
        let warningAffectedRes: AffectedReservation[] = [];
        if (affected?.length) {
          const blockingAffectedRes = affectedResByReason(affected, [
            AffectedReason.REMOVE_TABLE,
            AffectedReason.TABLE_DISABLE,
          ]);
          if (blockingAffectedRes.length) {
            throw new BlockingAffectedResError(blockingAffectedRes);
          }
          warningAffectedRes = affectedResByReason(affected, [
            AffectedReason.PAX_TABLE,
            AffectedReason.TABLE_AREA,
            AffectedReason.MAXIMUM_GUESTS,
          ]);
        }
        return { ...parsedData, warningAffectedRes };
      })
  );
};

export const useAddTables = () => {
  const v2Api = useV2Api();
  const mutation = useMutation(
    z
      .function()
      .args(z.array(AddAreaTablePayload))
      .implement(async (area: AddAreaTablePayload[]) => {
        const { data } = await v2Api.post(TABLE_ENDPOINT, area);
        return data;
      })
  );
  return mutation;
};

export const useUpdateArea = () => {
  const v2Api = useV2Api();
  const mutation = useMutation(
    z
      .function()
      .args(ZArea)
      .implement(async (area: Area) => {
        const id = z
          .string()
          .min(1)
          .parse(area.id || area.areaId);
        const { data } = await v2Api.put(`${AREA_ENDPOINT}/${id}`, area);
        return data;
      })
  );
  return mutation;
};

export const useUpdateTable = () => {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(ZAreaTable.extend({ name: z.string().min(1) }))
      .implement(async (table: AreaTable) => {
        const { data } = await v2Api.put(`${TABLE_ENDPOINT}/${table.id}`, {
          ...table,
          // HACK: Somehow the API for update require `rotate` need to be set
          rotate: table.rotate || 0,
        });
        return data;
      })
  );
};

export const useGetAreas = (
  config?: UseQueryOptions<
    GetAreasResponse,
    Error,
    GetAreasResponse,
    Array<any>
  >
) => {
  const v2Api = useV2Api();
  return useQuery(
    ['get-areas'],
    async () => {
      const { data } = await v2Api.get(AREA_ENDPOINT);
      return ZGetAreasResponse.parse(data);
    },
    { ...config }
  );
};

export const useDeleteArea = () => {
  const v2Api = useV2Api();
  const mutation = useMutation(
    z
      .function()
      .args(z.string().nullish())
      .implement(async (areaId?: string | null) => {
        const id = z.string().min(1).parse(areaId);
        const { data } = await v2Api.delete(`${AREA_ENDPOINT}/${id}`);
        return data;
      })
  );

  return mutation;
};

export const useDeleteTable = ({
  onDeleteFailed,
}: {
  onDeleteFailed: () => void;
}) => {
  const v2Api = useV2Api();
  const mutation = useMutation(
    z
      .function()
      .args(z.string().min(1))
      .implement(async (tableId: string) => {
        const { data } = await v2Api.delete(`${TABLE_ENDPOINT}/${tableId}`);
        return data;
      }),
    {
      onError: (err) => {
        if (isAxiosError(err) && err.response?.status === 400) {
          onDeleteFailed();
        }
      },
    }
  );

  return mutation;
};

export const useUpdateAreaLayout = () => {
  const v2Api = useV2Api();
  const mutation = useMutation(
    z
      .function()
      .args(ZUpdateAreaLayOutPayload)
      .implement(async (payload: UpdateAreaLayOutPayload) => {
        const { data } = await v2Api.post('/layouts/save-layout', payload);
        return data;
      })
  );
  return mutation;
};
