import {
  useMemo,
  useId,
  forwardRef,
  useRef,
  useEffect,
  ForwardedRef,
} from 'react';
import ReactSelect, {
  StylesConfig,
  components,
  ControlProps,
  OptionProps,
  Props,
  GroupBase,
  DropdownIndicatorProps,
} from 'react-select';
import { Box, FormHelperText, useTheme } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { css } from '@emotion/react';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>
  > {
    /** Disable state of select */
    disabled?: boolean;

    /** Label for the select */
    label?: string;

    /** Error message of select */
    error?: string;

    /** Allow auto width for options */
    autoWidth?: boolean;

    /** Custom max height for menu list */
    menuMaxHeight?: string;
  }
}

const Control = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: ControlProps<Option, IsMulti, Group>
) => {
  const { children, ...rest } = props;
  const { label } = props.selectProps;
  const childrenComponent = label ? (
    <>
      <Box
        className="select-label"
        sx={{
          position: 'absolute',
          left: 0,
          top: 'calc(50% - 12px)',
          width: '100%',
          padding: '0 14px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1)',
        }}
      >
        {label}
      </Box>
      {children}
    </>
  ) : (
    children
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <components.Control {...rest}>{childrenComponent}</components.Control>
    </Box>
  );
};

const DropdownIndicator = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>
) => {
  const { isSearchable, isClearable, menuIsOpen } = props.selectProps;
  const IconSize = {
    width: '24px',
    height: '24px',
  };

  if (isSearchable && !isClearable) {
    if (menuIsOpen) {
      return (
        <components.DropdownIndicator {...props}>
          <SearchIcon sx={IconSize} />
        </components.DropdownIndicator>
      );
    }
    return (
      <components.DropdownIndicator {...props}>
        <KeyboardArrowDownIcon sx={IconSize} />
      </components.DropdownIndicator>
    );
  }
  if (menuIsOpen) {
    return (
      <components.DropdownIndicator {...props}>
        <KeyboardArrowUpIcon sx={IconSize} />
      </components.DropdownIndicator>
    );
  }
  return (
    <components.DropdownIndicator {...props}>
      <KeyboardArrowDownIcon sx={IconSize} />
    </components.DropdownIndicator>
  );
};

const CustomOption = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: OptionProps<Option, IsMulti, Group>
) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    props.isSelected &&
      ref.current?.scrollIntoView({ block: 'nearest', inline: 'start' });
  }, [props.isSelected]);

  return (
    <components.Option
      {...props}
      // @ts-ignore invalid react-select ref option
      innerRef={ref}
      css={css`
        border-radius: 0;

        background-color: ${props.isSelected
          ? 'rgba(145, 158, 171, 0.16)'
          : ''};

        :hover {
          background: rgba(145, 158, 171, 0.08);
        }
      `}
    />
  );
};

const AdvanceSelect = function AdvanceSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>, ref: any) {
  const {
    disabled,
    error,
    isMulti,
    autoWidth,
    placeholder,
    menuMaxHeight,
    components,
    ...restProps
  } = props;
  const theme = useTheme();

  const customStyle = useMemo((): StylesConfig<Option, boolean, Group> => {
    return {
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: '#919EAB',
        };
      },
      menu: (provided) => ({
        ...provided,
        padding: '4px 8px',
        borderRadius: 16,
        backgroundColor: '#fff',
        zIndex: 2,
        ...(autoWidth && { width: 'auto' }),
      }),
      menuList: (styles) => {
        return {
          ...styles,
          ...(menuMaxHeight && { maxHeight: menuMaxHeight }),
        };
      },
      container: (styles, { selectProps }) => {
        const { placeholder, value } = selectProps;
        const shrinkMode = {
          padding: '0 4px',
          top: '-11px',
          left: '9px',
          fontSize: '12px',
          lineHeight: '18px',
          backgroundColor: '#FFF',
          width: 'fit-content',
          height: 'fit-content',
          maxWidth: '80%',
        };
        return {
          ...styles,
          '.select-label':
            placeholder || !isEmpty(value)
              ? {
                  ...shrinkMode,
                  color: !!error ? theme.palette.error.main : '#919EAB',
                }
              : {
                  color: !!error ? theme.palette.error.main : '#919EAB',
                },
          ':focus-within': {
            '.select-label': {
              ...shrinkMode,
              color: !!error
                ? theme.palette.error.main
                : theme.palette.primary.main,
            },
          },
        };
      },
      control: (styles, { isDisabled }) => ({
        ...styles,
        padding: '9px 0',
        borderRadius: '8px',
        backgroundColor: isDisabled ? theme.palette.grey[200] : 'transparent',
        ...(!!error && !disabled && { borderColor: theme.palette.error.main }),
        ':hover': {
          border: `1px solid ${!!error ? theme.palette.error.main : '#212B36'}`,
        },
        ':focus-within': {
          boxColor: !!error
            ? theme.palette.error.main
            : theme.palette.primary.main,
          borderColor: !!error
            ? theme.palette.error.main
            : theme.palette.primary.main,
          boxShadow: `0 0 0 1px ${
            !!error ? theme.palette.error.main : theme.palette.primary.main
          }`,
        },
      }),
      option: (styles, { isSelected }) => {
        return {
          ...styles,
          cursor: 'pointer',
          backgroundColor: isSelected
            ? theme.palette.grey[500_16]
            : 'transparent',
          color: theme.palette.text.primary,
          padding: '8px 16px',
          borderRadius: '8px',
          margin: '2px 0',
          ':hover': {
            backgroundColor: theme.palette.grey[500_16],
          },
        };
      },
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };
  }, [
    autoWidth,
    menuMaxHeight,
    error,
    theme.palette.error.main,
    theme.palette.primary.main,
    theme.palette.grey,
    theme.palette.text.primary,
    disabled,
  ]);

  return (
    <>
      <ReactSelect
        id={useId()}
        instanceId={useId()}
        ref={ref}
        components={{
          Control,
          Option: CustomOption,
          DropdownIndicator,
          IndicatorSeparator: null,
          ...components,
        }}
        classNamePrefix="advance-select"
        isDisabled={disabled}
        styles={customStyle}
        isMulti={isMulti}
        placeholder={placeholder || ''}
        blurInputOnSelect={true}
        menuPlacement="auto"
        {...restProps}
        css={css`
          .advance-select__control {
            padding: 15px 0;
          }
          .advance-select__value {
            padding: 0;
          }
          .advance-select__value-container {
            padding-top: 0;
            padding-bottom: 0;
          }
          .advance-select__menu {
            margin: 0;
            padding: 8px 0;
            box-shadow: ${theme.shadows[24]};
            overflow: hidden;
            border-radius: 12px;
          }
          .advance-select__input-container {
            margin: 0;
            padding: 0;
          }
          .advance-select__indicator {
            padding: 0;
            margin-right: 12px;
            color: #637381;
          }
        `}
      />
      {!!error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </>
  );
};

/**
 * Built on top of react-select <br/>
 * Using in cases searchable select, custom select option, value is an object. <br/>
 * NOTE: different from select, option in advance-select is the whole object <br/>
 * I.e. option is: {label: 'test label', value: 'test value'}
 * <ul>
 *     <li>Value in select: 'test value'</li>
 *     <li>Value in advance-select: {label: 'test label', value: 'test value'}</li>
 * </ul>
 */
const SelectWithRef = forwardRef(AdvanceSelect) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group> & {
    ref?: ForwardedRef<HTMLSelectElement>;
  }
) => ReturnType<typeof AdvanceSelect>;

export default SelectWithRef;
