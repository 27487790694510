import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import useV2Api from '@/common/hooks/use-v2-api';
import { useMemo } from 'react';
import { z } from 'zod';
import {
  AddTicketPayload,
  AddTicketResponse,
  GetTicketsParams,
  GetTicketsResponse,
  ReorderTicketPayload,
  ReorderTicketResponse,
  Ticket,
  UpdateTicketPayload,
  ZAddTicketPayload,
  ZAddTicketResponse,
  ZGetTicketsParams,
  ZGetTicketsResponse,
  ZReorderTicketPayload,
  ZTicket,
  ZUpdateTicketPayload,
} from '@/common/types/ticket';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import { qClient } from '@/common/lib/react-query';

export function useTickets({
  params,
  config,
}: {
  params: GetTicketsParams;
  config?: UseQueryOptions<
    GetTicketsResponse,
    Error,
    GetTicketsResponse,
    Array<any>
  >;
}) {
  const v2Api = useV2Api();
  const fetchTickets = useMemo(
    () =>
      z
        .function()
        .args(ZGetTicketsParams)
        .implement(async (getTicketsParams: GetTicketsParams) => {
          const { data } = await v2Api.get('/ticket', {
            params: getTicketsParams,
          });
          return ZGetTicketsResponse.parse(data);
        }),
    [v2Api]
  );

  const getTicketsQueryKeys = useMemo(() => {
    const { brandId, outletId } = params;
    return ['get-tickets', brandId, outletId];
  }, [params]);

  const optimisticUpdate = useCallbackRef(
    (updater: <T extends GetTicketsResponse | undefined>(x: T) => T) => {
      qClient.setQueryData(getTicketsQueryKeys, updater);
    }
  );

  const hardRefetch = useCallbackRef(() => {
    getTicketsQuery.remove();
    getTicketsQuery.refetch();
  });

  const getTicketsQuery = useQuery(
    getTicketsQueryKeys,
    () => fetchTickets(params),
    config
  );

  return {
    ...getTicketsQuery,
    optimisticUpdate,
    hardRefetch,
  };
}

export function useTicket({
  id,
  config,
}: {
  id: string | null;
  config?: UseQueryOptions<Ticket, Error, Ticket, Array<any>>;
}) {
  const v2Api = useV2Api();
  return useQuery(
    ['get-ticket-query', id],
    async () => {
      if (!id) throw Error('invalid id');
      const { data } = await v2Api.get(`/ticket/${id}`);
      return ZTicket.parse(data);
    },
    { enabled: !!id, ...config }
  );
}

export function useAddTicket(args?: {
  config?: UseMutationOptions<
    AddTicketResponse,
    Error,
    AddTicketPayload,
    unknown
  >;
}) {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(ZAddTicketPayload)
      .implement(async (addTicketPayload: AddTicketPayload) => {
        const { data } = await v2Api.post('/ticket', addTicketPayload);
        return ZAddTicketResponse.parse(data);
      }),
    args?.config
  );
}

export function useDeleteTicket(args?: {
  config?: UseMutationOptions<boolean, Error, string, unknown>;
}) {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(z.string())
      .implement(async (id: string) => {
        await v2Api.delete(`/ticket/${id}`);
        return true;
      }),
    args?.config
  );
}

export function useUpdateTicket(args?: {
  config?: UseMutationOptions<Ticket, Error, UpdateTicketPayload, unknown>;
}) {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(ZUpdateTicketPayload)
      .implement(async (ticket: UpdateTicketPayload) => {
        const { data } = await v2Api.patch(`/ticket/${ticket.id}`, ticket);
        return ZTicket.parse({
          ...data,
          price: +data.price,
          usualPrice: +data.usualPrice,
        });
      }),
    args?.config
  );
}

export function useReorderTicket(args?: {
  config?: UseMutationOptions<
    ReorderTicketResponse,
    Error,
    ReorderTicketPayload[],
    unknown
  >;
}) {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(z.array(ZReorderTicketPayload))
      .implement(async (tickets: ReorderTicketPayload[]) => {
        const { data } = await v2Api.patch(`/ticket/reorder-tickets`, tickets);
        return { success: data.successed, tickets };
      }),
    args?.config
  );
}

export function useTicketServiceTimings({
  id,
  config,
}: {
  id: string | null;
  config?: UseQueryOptions<Ticket, Error, Ticket, Array<any>>;
}) {
  const v2Api = useV2Api();
  return useQuery(
    ['get-ticket-service-timings-query', id],
    async () => {
      if (!id) throw Error('invalid id');
      const { data } = await v2Api.get(`/ticket/${id}/service-timings`);
      return ZTicket.parse(data);
    },
    { enabled: !!id, ...config }
  );
}
