/**
 * https://fettblog.eu/typescript-react-generic-forward-refs/#option-3%3A-augment-forwardref
 */
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

import React, {
  ForwardedRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  alpha,
  Box,
  Divider,
  styled,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import TextField from '@mui/material/TextField';
import { Trans, useTranslation } from 'next-i18next';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { format, isPast, isToday } from 'date-fns';
import { useReservationSummary } from '@/common/hooks/use-reservations';
import LoadingScreen from '@/common/components/loading-screen';
import startOfDay from 'date-fns/startOfDay';
import { Summary, SummaryPayload } from '../types/monthly-reservation-summary';
import PublicIcon from '@mui/icons-material/Public';
import BlockDisturb from '@/feat/reservation/components/modal-date-time/block-disturb';

const DescriptionItem = styled(Box)({
  display: 'flex',
  gap: '8px',
});

const DescriptionStatus = styled(Box)({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  marginTop: '6px',
});

const DescriptionNote = styled(Box)({
  width: 'calc(100% - 16px)',
});

const StyledLoadingBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '280px', // height is fixed with the height of calendar
});

type StaticDatePickerProps = {
  border: number;
  error: number;
};

const StaticDatePickerBox = styled(Box)<StaticDatePickerProps>(
  ({ theme, border, error }) => ({
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    minWidth: '496px',
    height: '100%',
    padding: '0 24px 16px 24px',
    background: '#FFF',
    border: border
      ? error
        ? `1px solid ${theme.palette.error.main}`
        : `1px solid ${theme.palette.grey[500_24]}`
      : 'none',
    borderRadius: '16px',

    '.MuiPickersCalendarHeader-switchViewButton': {
      display: 'none',
    },

    '>.MuiPickerStaticWrapper-root': {
      flexGrow: 1,
    },

    '.MuiPickerStaticWrapper-content': {
      height: '100%',

      '>div': {
        width: '100%',
        height: '100%',

        '>div': {
          width: '100%',
          height: 'auto',

          '>div': {
            width: '100%',
            height: '100%',
            maxHeight: 'unset', // override default calendar style
          },

          '.MuiCalendarPicker-root': {
            height: '100%',
            maxHeight: 'unset', // override default calendar style

            ['.MuiPickersCalendarHeader-root']: {
              marginBottom: '6px',

              'div[role="presentation"]': {
                pointerEvents: 'none',
              },
            },

            '>div': {
              height: '100%',
              padding: 0,

              '>div': {
                height: '100%',

                '>div[role=grid]': {
                  height: '100%',
                  display: 'flex',
                  flexFlow: 'column',

                  '>div:last-of-type': {
                    height: 'fit-content',
                    flexGrow: 1,
                  },

                  'div[role="presentation"]': {
                    minHeight: '280px',
                  },
                },
                '.MuiDayPicker-monthContainer': {
                  overflow: 'unset',
                },
              },
            },

            width: '100%',
          },
        },
      },

      'div[role=row]': {
        justifyContent: 'space-between',
        marginTop: '10px',
        marginBottom: '10px',

        '&:last-of-type': {
          marginBottom: 0,
        },

        '>div, >span': {
          width: '32px',
          height: '32px',
          position: 'relative',
        },
      },
    },
  })
);

type PaperContentProps = {
  events: Array<Summary>;
  value: any;
  hasToday: boolean;
  children?: React.ReactNode;
};

export const PaperContent: React.FC<PaperContentProps> = ({
  events,
  value,
  hasToday,
  children,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const titleDate = useMemo(() => {
    const dataDate = events?.find(
      ({ date }) =>
        value instanceof Date && date === format(value, 'yyyy-MM-dd')
    );
    const countServiceTimingRunning =
      dataDate?.serviceTimingsRunning?.length || 0;
    const countServiceTimingBlocked =
      dataDate?.serviceTimingsBlocked?.length || 0;
    if (dataDate?.isBlockOutDate) {
      if (countServiceTimingRunning === 0) {
        return t(
          '{{numBlockedService}}/{{numBlockedService}} online services are blocked on selected date',
          {
            numBlockedService: countServiceTimingBlocked,
          }
        );
      }
      return t(
        '{{numBlockedService}}/{{numLengthService}} online services blocked on selected date',
        {
          numBlockedService: countServiceTimingBlocked,
          numLengthService:
            countServiceTimingRunning + countServiceTimingBlocked,
        }
      );
    }
    if (countServiceTimingRunning === 0) {
      return t('No online services are setup on selected date');
    }
    if (dataDate?.isSpecial) {
      return t(
        '{{numService}} special online services are running on selected date',
        {
          numService: countServiceTimingRunning,
        }
      );
    }
    return t('{{numService}} online services are running on selected date', {
      numService: countServiceTimingRunning,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, value]);

  return (
    <Box>
      {hasToday && (
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            top: '46px',
            color: theme.palette.grey[600],
          }}
        >
          {titleDate}
        </Typography>
      )}
      {children}
    </Box>
  );
};

type CustomPickersDayProps = {
  selected: boolean;
};

const CustomPickersDay = styled(PickersDay<Date>)<CustomPickersDayProps>(
  ({ theme, selected }) => ({
    width: '32px',
    height: '32px',
    fontWeight: 500,
    lineHeight: 24 / 16,
    fontSize: '16px',
    ...(selected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.dark,
      },
      borderRadius: '50%',
    }),
  })
);

interface ServiceDatePickerProps<T extends Date[] | Date> {
  onChange: (value: T) => void;
  value: T | null;
  /**
   * Define if the calendar render today status or not
   *  - true: render today status
   *  - false: render partial blocked/ special day instead
   *  - default is true
   */
  hasToday?: boolean;
  error?: boolean;
  customTodayLabel?: string;
  customSelectedDayLabel?: string;
  customNoOnlineServiceDayLabel?: string;
  customPartialBlockedDayLabel?: string;
  customFullyBlockedDayLabel?: string;
  border?: boolean;
  allowSelectNoService?: boolean;
  sx?: SxProps<Theme> | undefined;
  allowSelectSpecialSchedule?: boolean;
}
const ServiceDatePicker = React.forwardRef(function ServiceDatePicker<
  T extends Date[] | Date
>(props: ServiceDatePickerProps<T>, ref: ForwardedRef<HTMLDivElement>) {
  const {
    onChange,
    value,
    hasToday = true,
    error = false,
    customTodayLabel,
    customSelectedDayLabel,
    customNoOnlineServiceDayLabel,
    customPartialBlockedDayLabel,
    customFullyBlockedDayLabel,
    border = false,
    allowSelectNoService = true,
    allowSelectSpecialSchedule = true,
    sx,
  } = props;
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [focusDate, setFocusDate] = useState(
    value && value instanceof Date ? value : new Date()
  ); // This variable for changing focus when select single date

  const datePickerRef = useRef();

  const getSummaryPayloadFromDate = (d: Date): SummaryPayload => {
    return {
      year: format(d, 'yyyy'),
      month: format(d, 'MM'),
    };
  };

  const [summaryPayload, setSummaryPayload] = useState<SummaryPayload>(
    getSummaryPayloadFromDate(focusDate)
  );

  const { data: events, isLoading } = useReservationSummary({
    params: summaryPayload,
    config: {
      refetchOnWindowFocus: false,
    },
  });

  const fullBlocked: Array<Summary> | undefined = events?.filter(
    ({ isBlockOutDate, serviceTimingsRunning }) =>
      isBlockOutDate &&
      serviceTimingsRunning &&
      serviceTimingsRunning.length === 0
  );

  const partiallyBlocked: Array<Summary> | undefined = events?.filter(
    ({ isBlockOutDate, serviceTimingsRunning }) =>
      isBlockOutDate &&
      serviceTimingsRunning &&
      serviceTimingsRunning.length !== 0
  );

  const specialService: Array<Summary> | undefined = events?.filter(
    ({ isSpecial }) => isSpecial
  );

  const noService: Array<Summary> | undefined = events?.filter(
    ({ message }) => message === 'No service'
  );

  const renderDayInPicker = (
    day: Date,
    selectedDays: Date[],
    pickersDayProps: PickersDayProps<Date>
  ): JSX.Element => {
    const { outsideCurrentMonth } = pickersDayProps;
    const formatDay = format(day, 'yyyy-MM-dd');
    const selected = Array.isArray(value)
      ? !!value.find(
          (item) =>
            format(startOfDay(item), 'yyyy-MM-dd') ===
            format(startOfDay(day), 'yyyy-MM-dd')
        )
      : value instanceof Date &&
        format(value, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd');
    const isFullBlocked = !!fullBlocked?.find(
      (item: { date: string }) => item.date === formatDay
    );
    const isPartiallyBlocked = !!partiallyBlocked?.find(
      (item: { date: string }) => item.date === formatDay
    );
    const isNoService = !!noService?.find(
      (item: { date: string }) => item.date === formatDay
    );
    const isSpecialService = !!specialService?.find(
      (item: { date: string }) => item.date === formatDay
    );

    const haveSelectSpecialSchedule =
      isSpecialService && !allowSelectSpecialSchedule;

    const shouldDisabled = () => {
      if (isPast(day) && !isToday(day)) return true;

      return (
        !hasToday &&
        (isFullBlocked ||
          isPartiallyBlocked ||
          haveSelectSpecialSchedule ||
          (!allowSelectNoService && isNoService))
      );
    };

    return (
      <Box
        key={formatDay}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 'calc(100%/7)',
          mb: 0.5,
        }}
      >
        {!outsideCurrentMonth && (
          <>
            <Box sx={{ position: 'relative' }}>
              <CustomPickersDay
                {...pickersDayProps}
                selected={selected}
                disabled={shouldDisabled()}
                sx={{
                  ...pickersDayProps.sx,
                  ...(isFullBlocked && {
                    ...(!hasToday && {
                      color: alpha(theme.palette.text.disabled, 0.8),
                    }),

                    '::after': {
                      content: '""',
                      position: 'absolute',
                      top: '50%',
                      left: '10%',
                      width: '80%',
                      height: '1px',
                      transform: 'rotate(-45deg)',
                      background: alpha(theme.palette.text.secondary, 0.32),
                    },
                  }),
                }}
              />
            </Box>
            {!hasToday && (isPartiallyBlocked || haveSelectSpecialSchedule) && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '-8px',
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  background: theme.palette.grey[300],
                  marginTop: '1px',
                }}
              />
            )}
            {isNoService && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '-8px',
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  background: theme.palette.error.light,
                }}
              />
            )}
          </>
        )}
      </Box>
    );
  };

  const findIndexDate = (dates: Array<Date>, date: Date) => {
    const dateTime = date.getTime();
    return dates.findIndex((item) => item.getTime() === dateTime);
  };

  const onMonthChange = (newDate: Date) => {
    setSummaryPayload(getSummaryPayloadFromDate(newDate));
  };

  const onDateChange = (newDate: Date | null) => {
    if (!newDate) return;
    if (Array.isArray(value)) {
      const array = value.slice();
      const date = startOfDay(newDate || new Date());
      const index = findIndexDate(array, date);
      if (index >= 0) {
        array.splice(index, 1);
      } else {
        array.push(date);
      }
      onChange(array as T);
    } else {
      onChange(newDate as T);
      setFocusDate(newDate || new Date());
    }
  };

  const handleFormatDayOfWeek = (day: string) => {
    if (i18n?.language?.startsWith('zh')) return day;

    return day.charAt(0).toUpperCase();
  };

  useEffect(() => {
    if (value && value instanceof Date) {
      setFocusDate(value);
    }
  }, [value]);

  return (
    <StaticDatePickerBox
      border={border ? 1 : 0}
      error={error ? 1 : 0}
      sx={sx}
      ref={datePickerRef}
    >
      <StaticDatePicker
        ref={ref}
        // @ts-ignore PaperContent props type definition is defined in componentsProps
        components={{ PaperContent }}
        componentsProps={{
          paperContent: {
            events: events,
            value,
            hasToday,
          },
        }}
        dayOfWeekFormatter={handleFormatDayOfWeek}
        displayStaticWrapperAs="desktop"
        renderDay={renderDayInPicker}
        value={focusDate}
        onChange={onDateChange}
        renderInput={(params) => <TextField {...params} />}
        onMonthChange={onMonthChange}
        loading={isLoading}
        renderLoading={() => (
          <StyledLoadingBox>
            <LoadingScreen />
          </StyledLoadingBox>
        )}
        autoFocus
      />
      <Divider sx={{ margin: '16px 0' }} />
      <Box
        sx={{
          color: theme.palette.text.primary,
          fontSize: '12px',
          lineHeight: '18px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: '8px 0',
        }}
      >
        {hasToday ? (
          <>
            <Box
              sx={{ display: 'flex', gap: '8px 16px', flexDirection: 'column' }}
            >
              <DescriptionItem>
                <DescriptionStatus
                  sx={{ background: theme.palette.primary.main }}
                />
                <DescriptionNote>
                  {customSelectedDayLabel || t('Selected date')}
                </DescriptionNote>
              </DescriptionItem>
              <DescriptionItem>
                <DescriptionStatus
                  sx={{ background: theme.palette.grey[300] }}
                />
                <DescriptionNote>
                  {customTodayLabel || t('Today')}
                </DescriptionNote>
              </DescriptionItem>
            </Box>
            <Box
              sx={{ display: 'flex', gap: '8px 16px', flexDirection: 'column' }}
            >
              <DescriptionItem>
                <DescriptionStatus
                  sx={{ background: theme.palette.error.light }}
                />
                <DescriptionNote>
                  {customNoOnlineServiceDayLabel ||
                    t('No online service set up')}
                </DescriptionNote>
              </DescriptionItem>
              <DescriptionItem>
                <DescriptionStatus
                  sx={{
                    position: 'relative',
                    background: theme.palette.grey[200],

                    '::after': {
                      content: '""',
                      position: 'absolute',
                      top: '50%',
                      left: '0',
                      width: '100%',
                      height: '1px',
                      transform: 'rotate(-45deg)',
                      background: theme.palette.text.secondary,
                    },
                  }}
                />
                <DescriptionNote>
                  {customFullyBlockedDayLabel || t('Fully blocked')}
                </DescriptionNote>
              </DescriptionItem>
            </Box>
            <Box
              sx={{ display: 'flex', gap: '8px 16px', flexDirection: 'column' }}
            >
              <DescriptionItem>
                <PublicIcon
                  sx={{ color: theme.palette.info.light, fontSize: '16px' }}
                />
                <DescriptionNote>
                  {t('Bookable online timeslot')}
                </DescriptionNote>
              </DescriptionItem>
              <DescriptionItem>
                <BlockDisturb />
                <DescriptionNote>
                  {t('Blocked online timeslot')}
                </DescriptionNote>
              </DescriptionItem>
            </Box>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: '8px 16px', flexWrap: 'wrap' }}>
            <DescriptionItem>
              <DescriptionStatus
                sx={{ background: theme.palette.primary.main }}
              />
              <DescriptionNote>
                {customSelectedDayLabel || t('Selected dates')}
              </DescriptionNote>
            </DescriptionItem>
            <DescriptionItem>
              <DescriptionStatus
                sx={{ background: theme.palette.error.light }}
              />
              <DescriptionNote>
                {customNoOnlineServiceDayLabel || t('No online service set up')}{' '}
                {!allowSelectNoService && (
                  <Box
                    component="span"
                    sx={{ color: theme.palette.text.disabled }}
                  >
                    (<Trans>Not selectable</Trans>)
                  </Box>
                )}
              </DescriptionNote>
            </DescriptionItem>
            <DescriptionItem>
              <DescriptionStatus sx={{ background: theme.palette.grey[300] }} />
              <DescriptionNote>
                {customPartialBlockedDayLabel ? (
                  customPartialBlockedDayLabel
                ) : (
                  <span>
                    {!allowSelectSpecialSchedule ? (
                      <Trans>Special/Partially blocked</Trans>
                    ) : (
                      <Trans>Partially blocked</Trans>
                    )}{' '}
                    <Box
                      component="span"
                      sx={{ color: theme.palette.text.disabled }}
                    >
                      (<Trans>Not selectable</Trans>)
                    </Box>
                  </span>
                )}
              </DescriptionNote>
            </DescriptionItem>
            <DescriptionItem>
              <DescriptionStatus
                sx={{
                  position: 'relative',
                  background: theme.palette.grey[200],

                  '::after': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '0',
                    width: '100%',
                    height: '1px',
                    transform: 'rotate(-45deg)',
                    background: theme.palette.text.secondary,
                  },
                }}
              />
              <DescriptionNote>
                {customFullyBlockedDayLabel ? (
                  customFullyBlockedDayLabel
                ) : (
                  <span>
                    <Trans component="span">Fully blocked</Trans>{' '}
                    <Box
                      component="span"
                      sx={{ color: theme.palette.text.disabled }}
                    >
                      (<Trans>Not selectable</Trans>)
                    </Box>
                  </span>
                )}
              </DescriptionNote>
            </DescriptionItem>
          </Box>
        )}
      </Box>
    </StaticDatePickerBox>
  );
});

export default ServiceDatePicker;
