import React, { useMemo } from 'react';
import { Trans } from 'next-i18next';
import { ValueOf } from '@/common/utils';
import {
  addMinutes,
  differenceInHours,
  differenceInMinutes,
  format,
  set,
} from 'date-fns';
import { TypeWindowBooking } from '@/common/types/schedule.base';
import useI18nTimeUtils from '@/common/i18n-time-utils';

export type AREA_TYPE = 'all' | 'specific';

export const SERVICE_DAYS_OPTIONS: Record<
  string,
  { value: number; label: React.ReactElement }
> = {
  monday: { value: 1, label: <Trans>Monday</Trans> },
  tuesday: { value: 2, label: <Trans>Tuesday</Trans> },
  wednesday: { value: 3, label: <Trans>Wednesday</Trans> },
  thursday: { value: 4, label: <Trans>Thursday</Trans> },
  friday: { value: 5, label: <Trans>Friday</Trans> },
  saturday: { value: 6, label: <Trans>Saturday</Trans> },
  sunday: { value: 0, label: <Trans>Sunday</Trans> },
};

export const useGenerateBookableTimeOptions = () => {
  const { formatDate } = useI18nTimeUtils();
  return useMemo(() => {
    let startTime = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });
    const endTime = set(new Date(), { hours: 23, minutes: 45, seconds: 0 });
    const result: Record<string, { value: string; label: string }> = {};

    while (differenceInMinutes(endTime, startTime) >= 0) {
      result[format(startTime, 'HH:mm:ss')] = {
        label: formatDate(startTime, { en: 'h:mm a' }),
        value: format(startTime, 'HH:mm:ss'),
      };
      startTime = addMinutes(startTime, 15);
    }

    return result;
  }, [formatDate]);
};

export const PAX_OPTIONS = Array.from({ length: 200 }, (_, i) => i + 1).map(
  (i) => ({
    value: i,
    label: i.toString(),
  })
);

export const RESERVATION_OPTIONS = (() => {
  const result = Array.from({ length: 250 }, (_, i) => i + 1).map((i) => ({
    value: i,
    label: i.toString(),
  }));

  return [
    {
      value: 0,
      label: <Trans>No maximum</Trans>,
    },
    ...result,
  ];
})();

export const useGenerateDiningDurationOptions = (): Record<
  | '30mins'
  | '1h'
  | '1h_30mins'
  | '2h'
  | '2h_30mins'
  | '3h'
  | '3h_30mins'
  | '4h'
  | '4h_30mins'
  | '5h'
  | '5h_30mins'
  | '6h',
  { value: number; label: string }
> => {
  const { formatSecondToTime } = useI18nTimeUtils();

  return useMemo(
    () => ({
      '30mins': { value: 1800, label: formatSecondToTime(1800) },
      '1h': { value: 3600, label: formatSecondToTime(3600) },
      '1h_30mins': { value: 5400, label: formatSecondToTime(5400) },
      '2h': { value: 7200, label: formatSecondToTime(7200) },
      '2h_30mins': { value: 9000, label: formatSecondToTime(9000) },
      '3h': { value: 10800, label: formatSecondToTime(10800) },
      '3h_30mins': { value: 12600, label: formatSecondToTime(12600) },
      '4h': { value: 14400, label: formatSecondToTime(14400) },
      '4h_30mins': { value: 16200, label: formatSecondToTime(16200) },
      '5h': { value: 18000, label: formatSecondToTime(18000) },
      '5h_30mins': { value: 19800, label: formatSecondToTime(19800) },
      '6h': { value: 21600, label: formatSecondToTime(21600) },
    }),
    [formatSecondToTime]
  );
};

export const useGenerateExpireTime = (): Record<
  | '30mins'
  | '1h'
  | '2h'
  | '3h'
  | '4h'
  | '5h'
  | '6h'
  | '12h'
  | '24h'
  | '36h'
  | '48h',
  { value: number; label: string }
> => {
  const { formatDuration } = useI18nTimeUtils();

  return useMemo(
    () => ({
      '30mins':{ value: 30, label: formatDuration({ minutes: 30 }) },
      '1h': { value: 60, label: formatDuration({ hours: 1 }) },
      '2h': { value: 120, label: formatDuration({ hours: 2 }) },
      '3h': { value: 180, label: formatDuration({ hours: 3 }) },
      '4h': { value: 240, label: formatDuration({ hours: 4 }) },
      '5h': { value: 300, label: formatDuration({ hours: 5 }) },
      '6h': { value: 360, label: formatDuration({ hours: 6 }) },
      '12h': { value: 720, label: formatDuration({ hours: 12 }) },
      '24h': { value: 1440, label: formatDuration({ hours: 24 }) },
      '36h': { value: 2160, label: formatDuration({ hours: 36 }) },
      '48h': { value: 2880, label: formatDuration({ hours: 48 }) },
    }),
    [formatDuration]
  );
};

export type BookingOption = {
  val: string;
  type: ValueOf<typeof TypeWindowBooking>;
};

export const useGenerateNotAllowSpecificBookingOptions = (): Record<
  | 'none'
  | '15mins'
  | '30mins'
  | '45mins'
  | '1h'
  | '2h'
  | '3h'
  | '4h'
  | '5h'
  | '6h'
  | '7h'
  | '8h'
  | '9h'
  | '10h'
  | '11h'
  | '12h'
  | '13h'
  | '14h'
  | '15h'
  | '16h'
  | '17h'
  | '18h'
  | '19h'
  | '20h'
  | '21h'
  | '22h'
  | '23h'
  | '24h'
  | '36h'
  | '48h',
  { value: string; label: React.ReactElement | string }
> => {
  const { formatDuration } = useI18nTimeUtils();

  return useMemo(
    () => ({
      none: {
        value: JSON.stringify({ val: '0', type: TypeWindowBooking.MINUTES }),
        label: <Trans>None</Trans>,
      },
      '15mins': {
        value: JSON.stringify({ val: '15', type: TypeWindowBooking.MINUTES }),
        label: formatDuration({ minutes: 15 }).replace('minute', 'min'),
      },
      '30mins': {
        value: JSON.stringify({ val: '30', type: TypeWindowBooking.MINUTES }),
        label: formatDuration({ minutes: 30 }).replace('minute', 'min'),
      },
      '45mins': {
        value: JSON.stringify({ val: '45', type: TypeWindowBooking.MINUTES }),
        label: formatDuration({ minutes: 45 }).replace('minute', 'min'),
      },
      '1h': {
        value: JSON.stringify({ val: '60', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 1 }),
      },
      '2h': {
        value: JSON.stringify({ val: '120', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 2 }),
      },
      '3h': {
        value: JSON.stringify({ val: '180', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 3 }),
      },
      '4h': {
        value: JSON.stringify({ val: '240', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 4 }),
      },
      '5h': {
        value: JSON.stringify({ val: '300', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 5 }),
      },
      '6h': {
        value: JSON.stringify({ val: '360', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 6 }),
      },
      '7h': {
        value: JSON.stringify({ val: '420', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 7 }),
      },
      '8h': {
        value: JSON.stringify({ val: '480', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 8 }),
      },
      '9h': {
        value: JSON.stringify({ val: '540', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 9 }),
      },
      '10h': {
        value: JSON.stringify({ val: '600', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 10 }),
      },
      '11h': {
        value: JSON.stringify({ val: '660', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 11 }),
      },
      '12h': {
        value: JSON.stringify({ val: '720', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 12 }),
      },
      '13h': {
        value: JSON.stringify({ val: '780', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 13 }),
      },
      '14h': {
        value: JSON.stringify({ val: '840', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 14 }),
      },
      '15h': {
        value: JSON.stringify({ val: '900', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 15 }),
      },
      '16h': {
        value: JSON.stringify({ val: '960', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 16 }),
      },
      '17h': {
        value: JSON.stringify({ val: '1020', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 17 }),
      },
      '18h': {
        value: JSON.stringify({ val: '1080', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 18 }),
      },
      '19h': {
        value: JSON.stringify({ val: '1140', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 19 }),
      },
      '20h': {
        value: JSON.stringify({ val: '1200', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 20 }),
      },
      '21h': {
        value: JSON.stringify({ val: '1260', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 21 }),
      },
      '22h': {
        value: JSON.stringify({ val: '1320', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 22 }),
      },
      '23h': {
        value: JSON.stringify({ val: '1380', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 23 }),
      },
      '24h': {
        value: JSON.stringify({ val: '1440', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 24 }),
      },
      '36h': {
        value: JSON.stringify({ val: '2160', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 36 }),
      },
      '48h': {
        value: JSON.stringify({ val: '2880', type: TypeWindowBooking.HOUR }),
        label: formatDuration({ hours: 48 }),
      },
    }),
    [formatDuration]
  );
};

export const useGenerateAllowSpecificBookingOptions = (): Record<
  | '1day'
  | '2days'
  | '3days'
  | '4days'
  | '5days'
  | '6days'
  | '1week'
  | '2weeks'
  | '3weeks'
  | '4weeks'
  | '1month'
  | '2months'
  | '3months'
  | '6months'
  | '1year',
  { value: string; label: string }
> => {
  const { formatDuration } = useI18nTimeUtils();
  return useMemo(
    () => ({
      '1day': {
        value: JSON.stringify({ val: '1440', type: TypeWindowBooking.DAY }),
        label: formatDuration({ days: 1 }),
      },
      '2days': {
        value: JSON.stringify({ val: '2880', type: TypeWindowBooking.DAY }),
        label: formatDuration({ days: 2 }),
      },
      '3days': {
        value: JSON.stringify({ val: '4320', type: TypeWindowBooking.DAY }),
        label: formatDuration({ days: 3 }),
      },
      '4days': {
        value: JSON.stringify({ val: '5760', type: TypeWindowBooking.DAY }),
        label: formatDuration({ days: 4 }),
      },
      '5days': {
        value: JSON.stringify({ val: '7200', type: TypeWindowBooking.DAY }),
        label: formatDuration({ days: 5 }),
      },
      '6days': {
        value: JSON.stringify({ val: '8640', type: TypeWindowBooking.DAY }),
        label: formatDuration({ days: 6 }),
      },
      '1week': {
        value: JSON.stringify({ val: '10080', type: TypeWindowBooking.WEEK }),
        label: formatDuration({ weeks: 1 }),
      },
      '2weeks': {
        value: JSON.stringify({ val: '20160', type: TypeWindowBooking.WEEK }),
        label: formatDuration({ weeks: 2 }),
      },
      '3weeks': {
        value: JSON.stringify({ val: '30240', type: TypeWindowBooking.WEEK }),
        label: formatDuration({ weeks: 3 }),
      },
      '4weeks': {
        value: JSON.stringify({ val: '40320', type: TypeWindowBooking.WEEK }),
        label: formatDuration({ weeks: 4 }),
      },
      '1month': {
        value: JSON.stringify({ val: '43200', type: TypeWindowBooking.MONTH }),
        label: formatDuration({ months: 1 }),
      },
      '2months': {
        value: JSON.stringify({ val: '86400', type: TypeWindowBooking.MONTH }),
        label: formatDuration({ months: 2 }),
      },
      '3months': {
        value: JSON.stringify({ val: '129600', type: TypeWindowBooking.MONTH }),
        label: formatDuration({ months: 3 }),
      },
      '6months': {
        value: JSON.stringify({ val: '259200', type: TypeWindowBooking.MONTH }),
        label: formatDuration({ months: 6 }),
      },
      '1year': {
        value: JSON.stringify({ val: '518400', type: TypeWindowBooking.YEAR }),
        label: formatDuration({ years: 1 }),
      },
    }),
    [formatDuration]
  );
};

/**
 * The shape of BOOKING_OPTIONS is:
 *  {
 *      value: {
 *          val: counting of unit, must be in type string to follow BE structure
 *          type: type of unit
 *      },
 *      label: string
 *  }
 *  BOOKING_OPTIONS needs 2 fields type & label to match with select component option
 *  IMPORTANT: select component requires value is primitive type, that's why JSON.parse is used to handle this case
 */
export const useGenerateBookingOptions = () => {
  const notAllowBookingOptions = useGenerateNotAllowSpecificBookingOptions();
  const allowBookingOptions = useGenerateAllowSpecificBookingOptions();
  return useMemo(
    () => ({
      ...notAllowBookingOptions,
      ...allowBookingOptions,
    }),
    [notAllowBookingOptions, allowBookingOptions]
  );
};

export const useGenerateSpecificTimeOptions = () => {
  const { formatDate } = useI18nTimeUtils();
  return useMemo(() => {
    let startTime = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });
    const endTime = set(new Date(), { hours: 23, minutes: 45, seconds: 0 });
    const result: Record<string, { value: string; label: string }> = {};

    while (differenceInHours(endTime, startTime) >= 0) {
      result[format(startTime, 'HH:mm:ss')] = {
        label: formatDate(startTime, { en: 'h:mm a' }),
        value: format(startTime, 'HH:mm:ss'),
      };
      startTime = addMinutes(startTime, 15);
    }

    return result;
  }, [formatDate]);
};

export const useGenerateIntervalOptions = () => {
  const { formatSecondToTime } = useI18nTimeUtils();
  return useMemo(
    () => ({
      '15mins': { value: 900, label: formatSecondToTime(900) },
      '30mins': { value: 1800, label: formatSecondToTime(1800) },
      '45mins': { value: 2700, label: formatSecondToTime(2700) },
      '1h': { value: 3600, label: formatSecondToTime(3600) },
      '1h_15mins': { value: 4500, label: formatSecondToTime(4500) },
      '1h_30mins': { value: 5400, label: formatSecondToTime(5400) },
      '1h_45mins': { value: 6300, label: formatSecondToTime(6300) },
      '2h': { value: 7200, label: formatSecondToTime(7200) },
      '2h_15mins': { value: 8100, label: formatSecondToTime(8100) },
      '2h_30mins': { value: 9000, label: formatSecondToTime(9000) },
      '2h_45mins': { value: 9900, label: formatSecondToTime(9900) },
      '3h': { value: 10800, label: formatSecondToTime(10800) },
      '3h_15mins': { value: 11700, label: formatSecondToTime(11700) },
      '3h_30mins': { value: 12600, label: formatSecondToTime(12600) },
      '3h_45mins': { value: 13500, label: formatSecondToTime(13500) },
      '4h': { value: 14400, label: formatSecondToTime(14400) },
      '4h_15mins': { value: 15300, label: formatSecondToTime(15300) },
      '4h_30mins': { value: 16200, label: formatSecondToTime(16200) },
      '4h_45mins': { value: 17100, label: formatSecondToTime(17100) },
      '5h': { value: 18000, label: formatSecondToTime(18000) },
      '5h_15mins': { value: 18900, label: formatSecondToTime(18900) },
      '5h_30mins': { value: 19800, label: formatSecondToTime(19800) },
      '5h_45mins': { value: 20700, label: formatSecondToTime(20700) },
      '6h': { value: 21600, label: formatSecondToTime(21600) },
    }),
    [formatSecondToTime]
  );
};

export const useGenerateGracePeriodOptions = () => {
  const { formatSecondToTime } = useI18nTimeUtils();
  return useMemo(
    () => ({
      none: { value: 0, label: <Trans>None</Trans> },
      '15mins': { value: 900, label: formatSecondToTime(900) },
      '30mins': { value: 1800, label: formatSecondToTime(1800) },
      '45mins': { value: 2700, label: formatSecondToTime(2700) },
      '1h': { value: 3600, label: formatSecondToTime(3600) },
    }),
    [formatSecondToTime]
  );
};
