import EE from 'events';
import TypedEmitter from 'typed-emitter';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { Trans } from 'next-i18next';
import Router from 'next/router';
import { SecondaryButton } from '../components/buttons';
import useCallbackRef from './use-callback-ref';
import { useIsoLayoutEffect } from '@/common/utils';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { atom, getDefaultStore, useAtom, useSetAtom } from 'jotai';

type DirtyResolution = 'LEAVE' | 'CANCEL';
type ShowWarning = (opts?: {
  onLeave?: () => void;
  onCancel?: () => void;
}) => void;

const isOpenAtom = atom(false);
const hasUnsavedChangeAtom = atom(false);
const emitter = new EE() as TypedEmitter<{
  WARNING_RESOLVED: (resolution: DirtyResolution) => void;
}>;
const showWarning: ShowWarning = ({ onLeave, onCancel } = {}) => {
  emitter.once('WARNING_RESOLVED', (resolution) => {
    if (resolution === 'LEAVE') onLeave?.();
    if (resolution === 'CANCEL') onCancel?.();
  });
  getDefaultStore().set(isOpenAtom, true);
};

export default function useUnsavedChange(hasUnsavedChange: boolean) {
  const setHasUnsavedChange = useSetAtom(hasUnsavedChangeAtom);
  useIsoLayoutEffect(() => {
    setHasUnsavedChange(hasUnsavedChange);
  }, [hasUnsavedChange]);

  /**
   * to trigger dirty check manually (eg: when closing a modal)
   */
  const checkBeforeLeave = useCallbackRef<
    () => Promise<
      | { isDirty: true; resolution: DirtyResolution }
      | { isDirty: false; resolution?: never }
    >
  >(
    () =>
      new Promise((resolve) => {
        if (hasUnsavedChange)
          return showWarning({
            onLeave: () => resolve({ isDirty: true, resolution: 'LEAVE' }),
            onCancel: () => resolve({ isDirty: true, resolution: 'CANCEL' }),
          });
        return resolve({ isDirty: false });
      })
  );
  return { checkBeforeLeave };
}

export const UnsavedChangeGlobalModal = () => {
  const [hasUnsavedChange, setHasUnsavedChange] = useAtom(hasUnsavedChangeAtom);
  const [isOpen, setIsOpen] = useAtom(isOpenAtom);
  const theme = useTheme();

  useIsoLayoutEffect(() => {
    const unloadHandler = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return (e.returnValue =
        'Are you sure you want to leave this page? Any unsaved changes will be lost.');
    };
    const nextHandler = (url: string, { shallow }: { shallow: boolean }) => {
      showWarning({
        onLeave: () => Router.push(url, url, { shallow }),
      });
      // Cancel routeChange event by erroring
      // See https://github.com/zeit/next.js/issues/2476
      const err = `routeChange aborted. This error can be safely ignored - https://github.com/zeit/next.js/issues/2476.`;
      Router.events.emit('routeChangeError', err);
      throw err;
    };
    if (hasUnsavedChange) {
      window.addEventListener('beforeunload', unloadHandler);
      Router.events.on('routeChangeStart', nextHandler);
    }
    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
      Router.events.off('routeChangeStart', nextHandler);
    };
  }, [hasUnsavedChange, showWarning]);

  const handleCancel = useCallbackRef(() => {
    setIsOpen(false);
    emitter.emit('WARNING_RESOLVED', 'CANCEL');
  });
  const handleAccept = useCallbackRef(() => {
    setHasUnsavedChange(false);
    setIsOpen(false);
    emitter.emit('WARNING_RESOLVED', 'LEAVE');
  });
  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <WarningTwoToneIcon
            sx={{ color: 'warning.main', fontSize: '24px' }}
          />
          <Typography variant="h6">
            <Trans>Leave Page?</Trans>
          </Typography>
        </Box>
        <IconButton
          sx={{
            color: theme.palette.action.active,
          }}
          onClick={handleCancel}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>
            Are you sure you want to leave this page? Any unsaved changes will
            be lost.
          </Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={handleCancel}>
          <Trans>Cancel</Trans>
        </SecondaryButton>
        <Button onClick={handleAccept} autoFocus>
          <Trans>Leave</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
