import { DialogActions, DialogActionsProps, useTheme } from '@mui/material';
import { css } from '@emotion/react';

export default function StickyBottomToolbar({ ...props }: DialogActionsProps) {
  const theme = useTheme();
  return (
    <DialogActions
      css={css`
        background: white;
        padding: 0 16px;
        z-index: 1;
        height: 60px;
        filter: drop-shadow(0px -8px 16px rgba(145, 158, 171, 0.24));

        & > .PrimaryButton-root {
          box-shadow: 0px 8px 16px ${theme.palette.primary.main_24};
        }
      `}
      {...props}
    />
  );
}
