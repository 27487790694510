import { z } from 'zod';
import { ZAreaWithoutRef } from './area.base';
import { ZServiceTimingWithoutRef } from './schedule.base';
import { ZTableInAreaWithoutRef } from './table-in-area.base';
import { ZTableWithoutRef } from '@/common/types/table.base';
import { ZReservationItem } from '@/common/types/reservation';

export const ZAreaTable = ZTableWithoutRef.extend({
  priority: z.number().nullish(),
  reservations: z.array(ZReservationItem).optional().nullable(),
  id: z.string().min(1),
});
export type AreaTable = z.infer<typeof ZAreaTable>;

export const ZArea = ZAreaWithoutRef.extend({
  serviceTimings: z.array(ZServiceTimingWithoutRef).optional().nullable(),
  tableInAreas: z.array(ZTableInAreaWithoutRef).optional().nullable(),
  tables: z.array(ZAreaTable).optional().nullable(),
});

export type Area = z.infer<typeof ZArea>;

export const AddAreaTablePayload = z.object({
  areaId: z.string(),
  maxPax: z.number(),
  minPax: z.number(),
  name: z.string(),
  bookableOnline: z.boolean(),
  priority: z.number(),
});
export type AddAreaTablePayload = z.infer<typeof AddAreaTablePayload>;

export const AddAreaPayload = z.object({
  displayName: z.string(),
  name: z.string(),
  order: z.number(),
  tables: z.array(AddAreaTablePayload),
});

export type AddAreaPayload = z.infer<typeof AddAreaPayload>;

export const ZGetAreasResponse = z.array(ZArea);

export type GetAreasResponse = z.infer<typeof ZGetAreasResponse>;

const BulkUpdateActionType = z.union([
  z.literal('KEEP'),
  z.literal('UPDATE'),
  z.literal('DELETE'),
]);

export const UpdateSingleTablePayload = ZAreaTable.extend({
  action: BulkUpdateActionType,
  blocks: z
    .array(z.object({ date: z.string(), from: z.string(), to: z.string() }))
    .optional(),
  blockTemporarily: z.boolean().optional(),
});
export type UpdateSingleTablePayload = z.infer<typeof UpdateSingleTablePayload>;

export const UpdateSingleAreaPayload = ZArea.extend({
  action: BulkUpdateActionType,
  tables: z.array(UpdateSingleTablePayload).optional().nullable(),
  blocks: z
    .array(z.object({ date: z.string(), from: z.string(), to: z.string() }))
    .optional(),
  blockTemporarily: z.boolean().optional(),
  blockIndefinitely: z.boolean().optional(),
});
export type UpdateSingleAreaPayload = z.infer<typeof UpdateSingleAreaPayload>;

export const UpdateAreasPayload = z.array(UpdateSingleAreaPayload);
export type UpdateAreasPayload = z.infer<typeof UpdateAreasPayload>;

export const GetAreasResponse = z.array(ZArea);

export const ZUpdateAreaLayOutPayload = z.array(
  z.object({
    x: z.number(),
    y: z.number(),
    tableId: z.string().nullable().optional(),
    areaId: z.string().nullable().optional(),
  })
);
export type UpdateAreaLayOutPayload = z.infer<typeof ZUpdateAreaLayOutPayload>;
