import { z } from 'zod';
import { ZTableWithoutRef } from './table.base';

export const ZPartySizeWithoutRef = z.object({
  id: z.string(),
  outletId: z.string(),
  pax: z.number(),
  createdTimestamp: z.string().nullish(),
  modifiedTimestamp: z.string().nullish(),
});

export const ZCombinationItem = z.object({
  id: z.string(),
  priority: z.number().nullish(),
  tables: z.array(ZTableWithoutRef).optional().nullable(),
  listPax: z.array(z.number()),
});

export const ZPartySize = ZPartySizeWithoutRef.extend({
  groupCombinations: z.array(ZCombinationItem).nullish(),
});

export const ZPartySizesRes = z.array(ZPartySize);

export type PartySizesRes = z.infer<typeof ZPartySizesRes>;

export type CombinationItem = z.infer<typeof ZCombinationItem>;

export type PartySize = z.infer<typeof ZPartySize>;

export const AddPartySizePayload = z.object({
  pax: z.number().min(1),
});

export type AddPartySizePayload = z.infer<typeof AddPartySizePayload>;

export const ZCreateCombinationPayload = z.object({
  combinations: z.array(
    z.object({
      tableIds: z.array(z.string()),
      listPax: z.array(z.string()),
    })
  ),
});

export const ZUpdateListCombinationsPayload = z.object({
  id: z.string(),
  combinations: z.array(
    z.object({
      id: z.string(),
      tableIds: z.array(z.string()).optional().nullable(),
      listPax: z.array(z.number()),
      priority: z.number(),
      action: z.string(),
    })
  ),
});

export type CreateCombinationPayload = z.infer<
  typeof ZCreateCombinationPayload
>;

export type UpdateListCombinationsPayload = z.infer<
  typeof ZUpdateListCombinationsPayload
>;
