import { z } from 'zod';
import { ZBrandWithoutRef } from './brand.base';
import { ZServiceTimingWithoutRef } from './schedule.base';
import {
  PR_TICKET_TYPE_PAYMENT,
  TICKET_TYPE_PRICE,
  ZTicketWithoutRef,
} from './ticket.base';

export const ZTicket = ZTicketWithoutRef.extend({
  serviceTimings: z.array(ZServiceTimingWithoutRef).optional().nullable(),
  brand: ZBrandWithoutRef.optional().nullable(),
});
export type Ticket = z.infer<typeof ZTicket>;

/**
 * PR: Payment Required
 */
export const ZPRTicket = ZTicket.extend({
  typePayment: z.nativeEnum(PR_TICKET_TYPE_PAYMENT),
  price: z.number().gt(0),
  typePrice: z.nativeEnum(TICKET_TYPE_PRICE),
});
/**
 * PR: Payment Required
 */
export type PRTicket = z.infer<typeof ZPRTicket>;

/**
 * PR: Payment Required
 */
export const isPRTicket = (ticket: unknown): ticket is PRTicket =>
  ZPRTicket.safeParse(ticket).success;

export const ZGetTicketsParams = z.object({
  brandId: z.string(),
  outletId: z.string().optional(),
  actor: z.string().optional(),
  limit: z.number().optional(),
  page: z.number().optional(),
});

export type GetTicketsParams = z.infer<typeof ZGetTicketsParams>;

export const ZGetTicketsResponse = z.object({
  items: z.array(ZTicket),
  meta: z.object({
    currentPage: z.number(),
    itemCount: z.number(),
    itemsPerPage: z.number(),
    totalItems: z.number(),
    totalPages: z.number(),
  }),
});

export type GetTicketsResponse = z.infer<typeof ZGetTicketsResponse>;

export const ZAddTicketBasePayLoad = z.object({
  brandId: z.string(),
  name: z.string(),
  color: z.string(),
  description: z.string().optional(),
  enableGoogleBooking: z.boolean(),
  image: z.string().optional(),
  isFeatureTicket: z.boolean(),
});
export type AddTicketBasePayLoad = z.infer<typeof ZAddTicketBasePayLoad>;

export const ZAddTicketPaymentPayLoad = z.object({
  typePayment: ZTicketWithoutRef.shape.typePayment,
  isBeforeSpecificTime: z.boolean().optional(),
  isShowUsualPrice: z.boolean().optional(),
  price: z.string().optional(),
  typePrice: z.nativeEnum(TICKET_TYPE_PRICE).nullish(),
  refundWindow: z.string().optional(),
  specificTime: z.string().nullable().optional(),
  typeRefundWindow: z.number().optional(),
  usualPrice: z.string().optional(),
});
export type AddTicketPaymentPayLoad = z.infer<typeof ZAddTicketPaymentPayLoad>;

export const ZAddTicketPayload = z.intersection(
  ZAddTicketBasePayLoad,
  ZAddTicketPaymentPayLoad
);
export type AddTicketPayload = z.infer<typeof ZAddTicketPayload>;

export const ZAddTicketResponse = z.object({
  brandId: z.string(),
  name: z.string(),
  color: z.string(),
  description: z.string().optional(),
  enableGoogleBooking: z.boolean(),
  image: z.string().optional(),
  isFeatureTicket: z.boolean(),
  isActive: z.boolean(),
  order: z.number(),
  id: z.string(),
  createdTimestamp: z.string().optional().nullable(),
  modifiedTimestamp: z.string().optional().nullable(),
  typePayment: ZTicketWithoutRef.shape.typePayment,
  isBeforeSpecificTime: z.boolean().optional(),
  isShowUsualPrice: z.boolean().optional(),
  price: z.string().optional(),
  typePrice: z.number().optional(),
  refundWindow: z.string().optional(),
  specificTime: z.string().nullable().optional(),
  typeRefundWindow: z.number().optional(),
  usualPrice: z.string().optional(),
});
export type AddTicketResponse = z.infer<typeof ZAddTicketResponse>;

export const ZUpdateTicketPayload = ZTicket.extend({
  price: z.union([
    z.string().nullable().optional(),
    z.number().nullable().optional(),
  ]),
  usualPrice: z.union([
    z.string().nullable().optional(),
    z.number().nullable().optional(),
  ]),
});

export type UpdateTicketPayload = z.infer<typeof ZUpdateTicketPayload>;

export const ZReorderTicketPayload = z.object({
  id: z.string(),
  order: z.number(),
});

export type ReorderTicketPayload = z.infer<typeof ZReorderTicketPayload>;

export const ZReorderTicketResponse = z.object({
  tickets: z.array(ZReorderTicketPayload),
  success: z.boolean(),
});

export type ReorderTicketResponse = z.infer<typeof ZReorderTicketResponse>;
