import { z } from 'zod';
import { TypeWindowBooking } from '@/common/types/schedule.base';
import { ZServiceTiming, ZBlockOutDate } from '@/common/types/schedule';
import { ZAffectedReservation } from '@/common/types/reservation';

export const GetServiceTimingsResponse = z.array(ZServiceTiming);
export type GetServiceTimingResponse = z.infer<
  typeof GetServiceTimingsResponse
>;

export const ZAddEditServiceTimingPayload = z.object({
  id: z.string().optional(),
  isRecurring: z.boolean(),
  days: z.array(z.number()),
  ticketId: z.string(),
  areaIds: z.array(z.string()),
  name: z.string(),
  specificDateArray: z.array(z.string()),
  dailyStartTime: z.string(),
  dailyEndTime: z.string(),
  recurringStartDate: z.string().nullable(),
  recurringEndDate: z.string().nullable(),
  specificStartTime: z.string(),
  specificEndTime: z.string(),
  maxPaxPerReservation: z.number().nullable(),
  leadTime: z.number(),
  gracePeriod: z.number().nullish(),
  diningInterval: z.number(),
  intervalTime: z.number(),
  bookingOnline: z.boolean(),
  minPartySize: z.number(),
  maxPartySize: z.number(),
  maxReservation: z.number().nullable(),
  minTimeBooking: z.string(),
  maxTimeBooking: z.string(),
  typeMinTimeBooking: z.nativeEnum(TypeWindowBooking),
  typeMaxTimeBooking: z.nativeEnum(TypeWindowBooking),
  minSpecificTimeBooking: z.string().nullable(),
  maxSpecificTimeBooking: z.string().nullable(),
  customReservationPolicy: z.boolean(),
  showArea: z.boolean(),
  isAllArea: z.boolean(),
  restaurantPolicy: z.string(),
  cancellationPolicy: z.string(),
  restaurantPolicyDefault: z.string().nullable(),
  cancellationPolicyDefault: z.string().nullable(),
});
export type AddEditServiceTimingPayload = z.infer<
  typeof ZAddEditServiceTimingPayload
>;

export const UpdateServiceTimingResponse = z.object({
  success: z.boolean(),
  serviceTiming: ZServiceTiming,
  affectedReservations: z.array(ZAffectedReservation).nullish(),
  listReservationAffect: z.array(ZAffectedReservation).nullish(),
});
export type UpdateServiceTimingResponse = z.infer<
  typeof UpdateServiceTimingResponse
>;

export type DataServiceTimingConverted = {
  titleSchedule: string;
  listServices?: GetServiceTimingResponse;
};

export const ZGetBlockOutDateResponse = z.array(ZBlockOutDate);
export type GetBlockOutDateResponse = z.infer<typeof ZGetBlockOutDateResponse>;

export const ZAddBlockOutDateItemPayload = z.object({
  date: z.string(),
  name: z.string().optional().nullable(),
  entireDay: z.boolean(),
  reason: z.string().optional().nullish(),
  serviceTimingIds: z.array(z.string()),
  handleAffectedReservationsAction: z.string().optional(),
  reasonStaffNotes: z.string().optional().nullish(),
});
export type AddBlockOutDateItemPayload = z.infer<
  typeof ZAddBlockOutDateItemPayload
>;

export const ZAddBlockOutDatePayload = z.array(ZAddBlockOutDateItemPayload);
export type AddBlockOutDatePayload = z.infer<typeof ZAddBlockOutDatePayload>;

export const ZAddBlockOutDateResponse = z.object({
  affectedReservations: z.array(ZAffectedReservation).nullish(),
  listReservationAffect: z.array(ZAffectedReservation).nullish(),
  success: z.boolean(),
});
export type AddBlockOutDateResponse = z.infer<typeof ZAddBlockOutDateResponse>;
export const GetAvailableTimeSlotPayload = z.object({
  date: z.string(),
  pax: z.number().optional().nullable(),
  isCheckMinPax: z.boolean().optional().nullable(),
  reservationId: z.string().optional().nullable(),
  ignoreSpecificServiceTiming: z.boolean().optional().nullable(),
  ignoreBlockOutDate: z.boolean().optional().nullable(),
});

export type GetAvailableTimeSlotPayload = z.infer<
  typeof GetAvailableTimeSlotPayload
>;

export const AvailableTimeSlotItem = z.object({
  listServiceTimingIds: z.array(z.string()),
  statusTimeSlot: z
    .enum(['DEFAULT', 'ENABLED_SERVICE_TIMING', 'BLOCKED_SERVICE_TIMING'])
    .nullable()
    .catch(null),
  time: z.string(),
  totalOfTable: z.number(),
  totalOfTableBooked: z.number(),
});

export type AvailableTimeSlotItem = z.infer<typeof AvailableTimeSlotItem>;

export const GetAvailableTimeSlotResponse = z.array(AvailableTimeSlotItem);

export type GetAvailableTimeSlotResponse = z.infer<
  typeof GetAvailableTimeSlotResponse
>;

export const GetServiceTimingsPayload = z
  .object({
    date: z.string().nullable().optional(),
    outletId: z.string().nullable().optional(),
  })
  .optional();

export type GetServiceTimingsPayload = z.infer<typeof GetServiceTimingsPayload>;
