import { selectAtom } from 'jotai/utils';
import { atom, useSetAtom, useAtomValue } from 'jotai';
import {
  AffectedReservation,
  ReservationItem,
} from '@/common/types/reservation';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import { useMemo } from 'react';
import { uniq } from 'lodash-es';
import { UpdateAreasPayload } from '@/common/types/area';
import { ServiceTiming } from '@/common/types/schedule';
import { hasBlockOverlap } from '@/common/components/table-block';

export type AffectedPayload =
  | {
      type: 'SCHEDULE_CONFIG_CHANGED';
      affected: AffectedReservation[];
      schedule?: ServiceTiming;
    }
  | {
      type: 'SCHEDULE_BLOCKED';
      affected: AffectedReservation[];
    }
  | {
      type: 'SCHEDULE_DELETED';
      affected: AffectedReservation[];
    }
  | {
      type: 'TABLE_DELETED_OR_DISABLED';
      affected: AffectedReservation[];
      pendingAreaChanges: UpdateAreasPayload;
      onAllResolved: () => void;
    }
  | {
      type: 'TABLE_CONFIG_CHANGED';
      affected: AffectedReservation[];
      pendingAreaChanges: UpdateAreasPayload;
    };
const affectedResAtom = atom<AffectedPayload | null>(null);
const allResolvedCallbackAtom = selectAtom(affectedResAtom, (it) =>
  it?.type === 'TABLE_DELETED_OR_DISABLED' ? it.onAllResolved : null
);
const pendingAreaChangesAtom = selectAtom(affectedResAtom, (it) => {
  if (it?.type === 'TABLE_DELETED_OR_DISABLED') {
    return it.pendingAreaChanges;
  }
  if (it?.type === 'TABLE_CONFIG_CHANGED') {
    return it.pendingAreaChanges;
  }
  return null;
});

export const useReservationResolveHandlers = () => {
  const setAffectedRes = useSetAtom(affectedResAtom);
  const allResolvedCallback = useAtomValue(allResolvedCallbackAtom);
  const reservationResolvedHandler = useCallbackRef(
    (updated: ReservationItem) => {
      setAffectedRes((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          affected: prev.affected.map((it) => {
            if (it.id === updated.id) {
              return {
                ...it,
                _resolved: updated,
              };
            }
            return it;
          }),
        };
      });
      // setEditingAffectedRes(null);
      // setCancellingAffectedRes(null);
    }
  );
  const allResolvedHandler = useCallbackRef(() => {
    allResolvedCallback?.();
    setAffectedRes(null);
  });

  const ignoreAllHandler = useCallbackRef(() => {
    setAffectedRes(null);
  });

  return { reservationResolvedHandler, allResolvedHandler, ignoreAllHandler };
};

export const useAffectedResDueToTableConfigChange = () => {
  const payload = useAtomValue(affectedResAtom);
  if (!payload || payload.type !== 'TABLE_CONFIG_CHANGED') return [];
  return payload.affected;
};

export const useAffectedResDueToUnavailTable = () => {
  const payload = useAtomValue(affectedResAtom);
  if (!payload || payload.type !== 'TABLE_DELETED_OR_DISABLED') return [];
  return payload.affected;
};

// unavailable table ids due to tables being removed or disabled in client-side
// (not yet saved to DB)
export const useUnavailTableIds = (selectedTime?: Date) => {
  const areas = useAtomValue(pendingAreaChangesAtom);
  return useMemo(() => {
    let ids: string[] = [];
    areas?.forEach((area) => {
      area.tables?.forEach((table) => {
        if (!table.enabled) ids.push(table.id);
        if (table.action === 'DELETE') ids.push(table.id);
        if (
          selectedTime &&
          hasBlockOverlap(table.tableBlocks || [], selectedTime)
        ) {
          ids.push(table.id);
        }
      });
    });
    return uniq(ids);
  }, [areas, selectedTime]);
};

export const useNewAffectedRes = () => useSetAtom(affectedResAtom);

export const useGetTablePendingConfig = () => {
  const pendingAreaChanges = useAtomValue(pendingAreaChangesAtom);
  return useCallbackRef((tableId: string | undefined | null) => {
    if (!tableId || !pendingAreaChanges) return null;
    for (const area of pendingAreaChanges) {
      for (const table of area.tables ?? []) {
        if (table.id === tableId && table.action !== 'DELETE') return table;
      }
    }
    return null;
  });
};

export const useAffectedResDueToDeletedSchedule = () => {
  const payload = useAtomValue(affectedResAtom);
  if (!payload || payload.type !== 'SCHEDULE_DELETED') return [];
  return payload.affected;
};

export const useAffectedResDueToBlockedSchedule = () => {
  const payload = useAtomValue(affectedResAtom);
  if (!payload || payload.type !== 'SCHEDULE_BLOCKED') return [];
  return payload.affected;
};

export const useAffectedResDueToScheduleConfigChange = () => {
  const payload = useAtomValue(affectedResAtom);
  if (!payload || payload.type !== 'SCHEDULE_CONFIG_CHANGED')
    return { affected: [], schedule: undefined };
  return { affected: payload.affected, schedule: payload.schedule };
};
