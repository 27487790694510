import { differenceInDays } from 'date-fns';
import { useCallback, useMemo } from 'react';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import {
  PRTicketTypePaymentInt,
  TICKET_TYPE_PAYMENT,
  TICKET_TYPE_PRICE,
} from '@/common/types/ticket.base';
import { formatCurrency } from '@/common/lib/money';
import { useTranslation } from 'next-i18next';
import useLocaleCfg from '@/common/hooks/use-locale-cfg';
import { assertUnreachable, ValueOf } from '@/common/utils';
import { TypeSelfEditWindow } from './constants';

export type ConsecutiveDates = Array<Array<Date>>;

export default function useTicketUtils() {
  const locale = useLocaleCfg();
  const { formatDate } = useI18nTimeUtils();
  const { t } = useTranslation();

  /**
   *
   * @param dateArr is list of sorted dates
   * split consecutive dates in dateArr to 2 dimensions array
   * Example:
   *  dateArray = ['2023-02-27', '2023-02-28', '2023-03-01', '2023-03-02', '2023-03-05', '2023-03-07', '2023-03-08']
   *  result: [
   *    ['2023-02-27','2023-03-02'],
   *    ['2023-03-05'],
   *    ['2023-03-07', '2023-03-08']
   *  ]
   */
  const splitConsecutiveDates = useCallbackRef((dateArr: Array<Date>) => {
    const result: ConsecutiveDates = [];
    if (dateArr.length <= 1) {
      result.push(dateArr);
      return result;
    }
    let consecutiveGroup = [dateArr[0]!];
    let checkDate = dateArr[0];
    for (let i = 1; i < dateArr.length; i++) {
      if (differenceInDays(dateArr[i]!, checkDate!) === 1) {
        consecutiveGroup.push(dateArr[i]!);
      } else {
        // if consecutiveGroup has 1 item, push to the result
        // if consecutiveGroup has more than 1, push the first and last items
        result.push(
          consecutiveGroup.length > 1
            ? [
                consecutiveGroup[0]!,
                consecutiveGroup[consecutiveGroup.length - 1]!,
              ]
            : consecutiveGroup
        );
        consecutiveGroup = [dateArr[i]!];
      }
      // when come to the last item, push consecutiveGroup to result
      if (i === dateArr.length - 1) {
        result.push(
          consecutiveGroup.length > 1
            ? [
                consecutiveGroup[0]!,
                consecutiveGroup[consecutiveGroup.length - 1]!,
              ]
            : consecutiveGroup
        );
      }
      checkDate = dateArr[i];
    }
    return result;
  });

  /**
   * @param consecutiveDates
   * Example:
   * input: [
   *    ['2023-02-27','2023-03-02'],
   *    ['2023-03-05'],
   *    ['2023-03-07', '2023-03-08']
   *  ]
   *  result: 27 Feb 2023 - 3 Mar 2023, 5 Mar 2023, 7 Mar 2023 - 8 Mar 2023
   */
  const generateLabelForConsecutiveDates = useCallbackRef(
    (consecutiveDates: ConsecutiveDates) => {
      return consecutiveDates
        .map((dates) =>
          dates
            .map((date) =>
              formatDate(date || new Date(), {
                en: 'd MMM yyyy',
                zh: 'yyyy MMM do',
              })
            )
            .join(' - ')
        )
        .join(', ');
    }
  );

  /**
   *
   * @param paymentPrice
   * @param paymentType
   * @param priceType
   *
   * Generate to: {price} {paymentType}/{priceType}
   * Example:
   *    S$50 Deposit/Pax
   *    S$100 Prepaid/Reservation
   *    S$150 Card Guarantee/Pax
   */
  const generatePrice = useCallbackRef(
    (
      paymentPrice: number,
      paymentType: PRTicketTypePaymentInt,
      priceType: ValueOf<typeof TICKET_TYPE_PRICE>
    ) => {
      const typePriceLabel =
        priceType === TICKET_TYPE_PRICE.PER_PAX ? t('Pax') : t('Reservation');
      let ticketDetail;
      switch (paymentType) {
        case TICKET_TYPE_PAYMENT['Deposit']:
          ticketDetail = `${t('Deposit')}/${typePriceLabel}`;
          break;
        case TICKET_TYPE_PAYMENT['Prepaid']:
          ticketDetail = `${t('Prepaid')}/${typePriceLabel}`;
          break;
        case TICKET_TYPE_PAYMENT['CardGuarantee']:
          ticketDetail = `${t('Card Guarantee')}/${typePriceLabel}`;
          break;
        default:
          assertUnreachable(paymentType);
          break;
      }
      return `${formatCurrency(paymentPrice, locale)} ${ticketDetail}`;
    }
  );

  const generatePriceOfTicket = useCallbackRef(
    (
      paymentPrice: number,
      paymentType: PRTicketTypePaymentInt,
      priceType: ValueOf<typeof TICKET_TYPE_PRICE> | unknown | null
    ) => {
      const paymentTypeLabels = {
        [TICKET_TYPE_PAYMENT['Deposit']]: t('Deposit'),
        [TICKET_TYPE_PAYMENT['Prepaid']]: t('Prepaid'),
        [TICKET_TYPE_PAYMENT['CardGuarantee']]: t('Card Guarantee'),
      };

      const paymentLabel = paymentTypeLabels[paymentType];
      if (!paymentLabel) {
        throw new Error(`Invalid payment type: ${paymentType}`);
      }

      const formattedPrice = formatCurrency(paymentPrice, locale);

      return priceType === TICKET_TYPE_PRICE.PER_PAX
        ? t('{{paymentLabel}} {{formattedPrice}} per pax', {
            paymentLabel,
            formattedPrice,
          })
        : t('{{paymentLabel}} {{formattedPrice}} per reservation', {
            paymentLabel,
            formattedPrice,
          });
    }
  );

  const paymentDetailsPrice = useCallbackRef(
    (paymentType: PRTicketTypePaymentInt) => {
      const paymentTypeTranslations: Record<string, string> = {
        [TICKET_TYPE_PAYMENT.Deposit]: t('Deposit'),
        [TICKET_TYPE_PAYMENT.Prepaid]: t('Prepaid'),
        [TICKET_TYPE_PAYMENT.CardGuarantee]: t('Card Guarantee'),
      };

      const ticketDetail = paymentTypeTranslations[paymentType] || '';
      return ticketDetail;
    }
  );

  return useMemo(
    () => ({
      splitConsecutiveDates,
      generateLabelForConsecutiveDates,
      generatePrice,
      paymentDetailsPrice,
      generatePriceOfTicket,
    }),
    [
      splitConsecutiveDates,
      generateLabelForConsecutiveDates,
      generatePrice,
      paymentDetailsPrice,
      generatePriceOfTicket,
    ]
  );
}

export const useTicketSelfEditUnitLabel = () => {
  const { t } = useTranslation();
  const typeSelfEditWindowSingularTransMaps: Record<string, string> = useMemo(
    () => ({
      [TypeSelfEditWindow.MINUTE]: t('minute'),
      [TypeSelfEditWindow.HOUR]: t('hour'),
      [TypeSelfEditWindow.DAY]: t('day'),
      [TypeSelfEditWindow.WEEK]: t('week'),
      [TypeSelfEditWindow.MONTH]: t('month'),
      [TypeSelfEditWindow.YEAR]: t('year'),
      [TypeSelfEditWindow.ANYTIME]: t('any time'),
    }),
    [t]
  );
  const typeSelfEditWindowPluralTransMaps: Record<string, string> = useMemo(
    () => ({
      [TypeSelfEditWindow.MINUTE]: t('minutes'),
      [TypeSelfEditWindow.HOUR]: t('hours'),
      [TypeSelfEditWindow.DAY]: t('days'),
      [TypeSelfEditWindow.WEEK]: t('weeks'),
      [TypeSelfEditWindow.MONTH]: t('months'),
      [TypeSelfEditWindow.YEAR]: t('years'),
      [TypeSelfEditWindow.ANYTIME]: t('any time'),
    }),
    [t]
  );
  const fn = useCallback(
    (val: string | null | undefined, config?: { isPlural: boolean }) => {
      const map = config?.isPlural
        ? typeSelfEditWindowPluralTransMaps
        : typeSelfEditWindowSingularTransMaps;
      return val ? map[val] || '' : '';
    },
    [typeSelfEditWindowPluralTransMaps, typeSelfEditWindowSingularTransMaps]
  );
  return fn;
};

export const useTicketEditWindowLabel = () => {
  const getTicketSelfEditUnitLabel = useTicketSelfEditUnitLabel();
  const fn = useCallback(
    (
      value: number | null | undefined,
      unit: TypeSelfEditWindow | null | undefined
    ) => {
      const isPlural = value !== null && value !== undefined && value !== 1;
      if (unit === TypeSelfEditWindow.ANYTIME) {
        return getTicketSelfEditUnitLabel(unit, { isPlural });
      }
      if (!value || !unit) {
        return '';
      }
      return `${value} ${getTicketSelfEditUnitLabel(unit, { isPlural })}`;
    },
    [getTicketSelfEditUnitLabel]
  );
  return fn;
};

export const getTicketEditWindowValue = (
  value: number | null | undefined,
  unit: TypeSelfEditWindow | null | undefined
) => {
  if (!value || !unit) {
    return 0;
  }
  const unitInMinutes = {
    [TypeSelfEditWindow.MINUTE]: 1,
    [TypeSelfEditWindow.HOUR]: 60,
    [TypeSelfEditWindow.DAY]: 60 * 24,
    [TypeSelfEditWindow.WEEK]: 60 * 24 * 7,
    [TypeSelfEditWindow.MONTH]: 60 * 24 * 30,
    [TypeSelfEditWindow.YEAR]: 60 * 24 * 365,
  };

  if (unit === TypeSelfEditWindow.ANYTIME) {
    return 0;
  }
  return value * unitInMinutes[unit];
};
