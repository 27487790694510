import React from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';
import useOutletSwitch from '@/feat/auth/use-outlet-switch';
import { useSession } from '@/feat/auth/context';
import { env } from '@/env.mjs';
import useCallbackRef from '@/common/hooks/use-callback-ref';

export const analytics = AnalyticsBrowser.load({
  writeKey: env.NEXT_PUBLIC_ANALYTICS_TRACKING_SOURCE_ID,
});

export const callAnalytics = ({
  accountId,
  accountName,
  brandId,
  brandName,
}: {
  accountId?: string;
  accountName?: string;
  brandId?: string;
  brandName?: string;
}) => {
  analytics.page(
    undefined,
    undefined,
    {
      accountId,
      accountName,
      brandId,
      brandName,
    },
    { context: { groupId: brandId } }
  );
};

export const usePageAnalytics = () => {
  const { selectedBrand } = useOutletSwitch();
  const { data: sessionData } = useSession();

  React.useEffect(() => {
    callAnalytics({
      accountId: sessionData?.omsAccount?.id,
      accountName: sessionData?.omsAccount?.name,
      brandId: selectedBrand?.omsBrandId,
      brandName: selectedBrand?.brandName,
    });
  }, [
    selectedBrand?.brandName,
    selectedBrand?.omsBrandId,
    sessionData?.omsAccount?.id,
    sessionData?.omsAccount?.name,
  ]);
};

export const usePageEvent = () => {
  const { selectedBrand } = useOutletSwitch();
  const { data: sessionData } = useSession();
  const pageEvent = useCallbackRef(() =>
    callAnalytics({
      accountId: sessionData?.omsAccount?.id,
      accountName: sessionData?.omsAccount?.name,
      brandId: selectedBrand?.omsBrandId,
      brandName: selectedBrand?.brandName,
    })
  );

  return pageEvent;
};
