import pMemo from 'p-memoize';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { ServiceTiming, ZServiceTiming } from '@/common/types/schedule';
import {
  AddEditServiceTimingPayload,
  GetAvailableTimeSlotPayload,
  GetAvailableTimeSlotResponse,
  GetServiceTimingsPayload,
  GetServiceTimingsResponse,
  UpdateServiceTimingResponse,
  ZAddEditServiceTimingPayload,
} from '@/feat/schedule/types';
import useV2Api from '@/common/hooks/use-v2-api';
import { z } from 'zod';
import { useCallback, useMemo } from 'react';
import { ZAffectedReason, ZAffectedReservation } from '../types/reservation';
import { useNewAffectedRes } from '@/feat/affected-reservations/atoms';
import { add, format, parse, sub } from 'date-fns';

export function useSchedules(args?: {
  params?: GetServiceTimingsPayload;
  config: UseQueryOptions<ServiceTiming[], Error, ServiceTiming[], Array<any>>;
}) {
  const v2Api = useV2Api();

  const fetchServiceTimings = useMemo(
    () =>
      z
        .function()
        .args(GetServiceTimingsPayload)
        .implement(async (params) => {
          const { data } = await v2Api.get('/service-timings', {
            params,
          });
          return GetServiceTimingsResponse.parse(data);
        }),
    [v2Api]
  );
  const getSchedulesQueryKeys = useMemo(() => {
    if (!args?.params) {
      return ['get-schedules-query'];
    }

    const { date, outletId } = args.params;
    return ['get-reservations', date, outletId];
  }, [args?.params]);

  return useQuery(
    getSchedulesQueryKeys,
    () => fetchServiceTimings(args?.params),
    args?.config
  );
}

export function useSchedule(args: {
  scheduleId: string | null;
  config?: UseQueryOptions<ServiceTiming, Error, ServiceTiming, Array<any>>;
}) {
  const { config, scheduleId } = args;
  const v2Api = useV2Api();
  const getScheduleQuery = useQuery(
    ['get-schedules-query', scheduleId],
    async () => {
      if (!scheduleId) throw Error('invalid scheduleId');
      const { data } = await v2Api.get(`/service-timings/${scheduleId}`);
      return ZServiceTiming.parse(data);
    },
    { enabled: !!scheduleId, ...config }
  );
  return getScheduleQuery;
}

export function useAddSchedule(args?: {
  config?: UseMutationOptions<
    unknown,
    Error,
    AddEditServiceTimingPayload,
    unknown
  >;
}) {
  const newAffectedRes = useNewAffectedRes();
  const v2Api = useV2Api();
  const addScheduleMutation = useMutation(
    z
      .function()
      .args(ZAddEditServiceTimingPayload)
      .implement(async (newServiceTiming: AddEditServiceTimingPayload) => {
        const { data } = await v2Api.post('/service-timings', newServiceTiming);
        const affected = z
          .array(ZAffectedReservation)
          .nullish()
          .parse(data.affectedReservations || data.listReservationAffect);
        if (affected?.length) {
          newAffectedRes({
            type: 'SCHEDULE_CONFIG_CHANGED',
            affected,
          });
        }
        return true;
      }),
    args?.config
  );
  return addScheduleMutation;
}

export function useUpdateSchedule(args?: {
  config?: UseMutationOptions<
    UpdateServiceTimingResponse,
    Error,
    AddEditServiceTimingPayload,
    unknown
  >;
}) {
  const newAffectedRes = useNewAffectedRes();
  const v2Api = useV2Api();
  const updateScheduleMutation = useMutation(
    z
      .function()
      .args(ZAddEditServiceTimingPayload)
      .implement(async (serviceTiming: AddEditServiceTimingPayload) => {
        const { data } = await v2Api.put(
          `/service-timings/${serviceTiming.id}`,
          serviceTiming
        );
        let res;
        const needWorkaround =
          data.reason &&
          data.affectedReservations?.length &&
          data.affectedReservations.every((it: any) => !it.reasons);
        if (needWorkaround) {
          // TODO: remove this workaround after BE updates API
          res = UpdateServiceTimingResponse.extend({
            serviceTiming: z.any(),
            reason: z.array(ZAffectedReason).min(1).nullish(),
          }).parse({
            ...data,
            affectedReservations: data.affectedReservations?.map((it: any) => ({
              ...it,
              reasons: data.reason,
            })),
          }) as z.infer<typeof UpdateServiceTimingResponse>;
        } else {
          res = UpdateServiceTimingResponse.parse(data);
        }
        const affected = res.affectedReservations || res.listReservationAffect;
        if (affected?.length) {
          newAffectedRes({
            type: 'SCHEDULE_CONFIG_CHANGED',
            affected,
            schedule: res.serviceTiming,
          });
        }
        return res;
      }),
    args?.config
  );
  return updateScheduleMutation;
}

export function useDeleteSchedule(args?: {
  config?: UseMutationOptions<boolean, Error, string, unknown>;
}) {
  const v2Api = useV2Api();
  const newAffectedRes = useNewAffectedRes();
  const removeScheduleMutation = useMutation(
    z
      .function()
      .args(z.string().min(1))
      .implement(async (id: string) => {
        const { data } = await v2Api.delete(`/service-timings/${id}`);
        const { affectedReservations } = z
          .object({
            affectedReservations: z.array(ZAffectedReservation).nullish(),
          })
          .parse(data);
        if (affectedReservations?.length) {
          newAffectedRes({
            type: 'SCHEDULE_DELETED',
            affected: affectedReservations,
          });
        }
        return true;
      }),
    args?.config
  );
  return removeScheduleMutation;
}

export function useGetAvailableTimeSlot(args: {
  params: GetAvailableTimeSlotPayload;
  config?: UseQueryOptions<
    GetAvailableTimeSlotResponse,
    Error,
    GetAvailableTimeSlotResponse,
    Array<any>
  >;
}) {
  const { config, params } = args;
  const v2Api = useV2Api();
  const fetchTimeSlotsAvailable = useMemo(
    () =>
      z
        .function()
        .args(GetAvailableTimeSlotPayload)
        .implement(async (params: GetAvailableTimeSlotPayload) => {
          const { data } = await v2Api.get(
            `/service-timings/available-by-outlet`,
            { params }
          );
          return GetAvailableTimeSlotResponse.parse(data);
        }),
    [v2Api]
  );

  const getTimeSlotsAvailableQueryKeys = useMemo(() => {
    if (!params) {
      return ['get-time-slot-available'];
    }
    const {
      date,
      pax,
      isCheckMinPax,
      reservationId,
      ignoreSpecificServiceTiming,
      ignoreBlockOutDate,
    } = params;
    return [
      'get-time-slot-available',
      date,
      pax,
      isCheckMinPax,
      reservationId,
      ignoreSpecificServiceTiming,
      ignoreBlockOutDate,
    ];
  }, [params]);

  const getTimeSlotsAvailableQuery = useQuery(
    getTimeSlotsAvailableQueryKeys,
    () => fetchTimeSlotsAvailable(params),
    config
  );
  return getTimeSlotsAvailableQuery;
}

export function useGetAvailableTimeSlotInfiniteByDate({
  date,
  enabled,
  isShowTimeslotST = false,
}: {
  date: string;
  enabled: boolean;
  isShowTimeslotST?: boolean;
}) {
  const dateFmt = 'yyyy-MM-dd';
  const queryClient = useQueryClient();
  const v2Api = useV2Api();

  const prevDate = (date: string) =>
    format(sub(parse(date, dateFmt, new Date()), { days: 1 }), dateFmt);
  const nextDate = (date: string) =>
    format(add(parse(date, dateFmt, new Date()), { days: 1 }), dateFmt);

  const memoFetchAvailableTimeslots = useMemo(
    () =>
      pMemo(async (date: string) => {
        const { data } = await v2Api.get(
          `/service-timings/available-by-outlet`,
          { params: { date, isShowTimeslotST } }
        );
        return {
          timeSlots: GetAvailableTimeSlotResponse.parse(data),
          date,
        };
      }),
    [v2Api, isShowTimeslotST]
  );

  const getTimeSlotsAvailableQuery = useInfiniteQuery(
    ['get-time-slot-available-infinite', date, isShowTimeslotST],
    async ({ pageParam = date }) => {
      // prefetch prev and next dates
      memoFetchAvailableTimeslots(prevDate(pageParam));
      memoFetchAvailableTimeslots(nextDate(pageParam));

      return await memoFetchAvailableTimeslots(pageParam);
    },
    {
      enabled,
      getNextPageParam: (lastPage) => nextDate(lastPage.date),
      getPreviousPageParam: (lastPage) => {
        if (parse(lastPage.date, dateFmt, new Date()) <= new Date()) {
          return;
        }
        return prevDate(lastPage.date);
      },
    }
  );

  const resetQuery = useCallback(() => {
    queryClient.resetQueries({
      queryKey: ['get-time-slot-available-infinite', date, isShowTimeslotST],
    });
  }, [date, isShowTimeslotST, queryClient]);

  return { getTimeSlotsAvailableQuery, resetQuery };
}
